import { Device } from '@services/devicemanager/Device.js';

import { isEqual, toPairs } from 'lodash-es';

const priv = Symbol('priv');
// This value can be adjusted upward if we wish to include small positive numbers as "valid".
const MAX_VALID_RSSI_VALUE = -1;
// This well below the typical minimally usable signal strength indication.
const MINIMUM_PRACTICAL_RSSI = -127;

// Events:
//
// "rssi-changed": int rssi
// "connectable-changed": bool

export class BluetoothDevice extends Device {
  constructor(args) {
    super('bluetooth', args);
    this[priv] = { rssi: args.rssi, connectable: args.connectable };

    this.lastValidRssi = MINIMUM_PRACTICAL_RSSI; // if we haven't yet received a valid rssi, this will keep the device at the bottom of the list.
  }

  get rssi() {
    return this[priv].rssi;
  }

  get connectable() {
    return this[priv].connectable;
  }

  // This is where we bottleneck the decision-making logic for "valid" rssi
  get isRssiValid() {
    return this[priv].rssi <= MAX_VALID_RSSI_VALUE;
  }

  // Call this method after setting rssi. It is the bottleneck for determining whether we should
  // save the value or not in our `lastValidRssi` property.
  _updateLastValidRssi() {
    if (this.rssi <= MAX_VALID_RSSI_VALUE) {
      this.lastValidRssi = this.rssi;
    } else {
      this.lastValidRssi = MINIMUM_PRACTICAL_RSSI;
    }
  }

  _update(props = {}) {
    super._update(props);

    const propMap = {
      rssi: 'rssi-changed',
      connectable: 'connectable-changed',
    };

    toPairs(propMap).forEach(([prop, propEvent]) => {
      const newValue = props[prop];
      const currentValue = this[priv][prop];
      if (prop in props && !isEqual(newValue, currentValue)) {
        this[priv][prop] = newValue;
        this.emit(propEvent, newValue);
      }
    });
    this._updateLastValidRssi();
  }
}
