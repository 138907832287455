const wakeLock = {};

export function requestWakeLock() {
  if (wakeLock.screen) {
    return;
  }

  if ('wakeLock' in navigator) {
    navigator.wakeLock
      .request('screen')
      .then(w => {
        wakeLock.screen = w;
      })
      .catch(err => {
        console.warn(err);
      });
  }
}

export function releaseWakeLock() {
  if (!wakeLock.screen) {
    return;
  }

  wakeLock.screen
    .release()
    .then(() => {
      wakeLock.screen = null;
    })
    .catch(err => {
      console.error(err);
    });
}
