import EventEmitter from 'eventemitter3';
import * as platform from './PLATFORM_ID';

// TODO: flesh this out with the File Handling API
class StartupFileHandler extends EventEmitter {
  constructor() {
    super();
    if ('launchQueue' in window) {
      window.launchQueue.setConsumer(async launchParams => {
        if (!launchParams?.files?.length) return;
        const fileHandle = launchParams.files[0]; // grab the first fileHandle
        this._startFile = true;
        this._startFileData = { file: await fileHandle.getFile(), filepath: '' };
        this.processStartFile(this._startFileData);
      });
    }
  }

  processStartFile(file) {
    this.emit('new-start-file', file);
  }

  get startFileData() {
    return this._startFileData;
  }

  get startFile() {
    return this._startFile;
  }
}

export const createStartupFileHandler = (dataWorld, fileIO, toast) => {
  if (platform.createStartupFileHandler) {
    return platform.createStartupFileHandler(dataWorld, fileIO, toast);
  }
  return new StartupFileHandler();
};
