import { eventHandlers as sensorWorldEventHandlers } from '@services/sensorworld/SensorWorld.handlers.js';
import { nextTick } from '@utils/nextTick.js';

export const exportSensorWorld = sensorWorld => {
  const out = {};

  out.sensors = sensorWorld.sensors.map(s => {
    return {
      uniqueId: s.id, //
      autoId: s.autoId,
      key: s.sensorId, //
      units: s.units,
      name: s.name,
      sensorInfo: s.sensorInfo,
      zeroOnCollect: s.zeroOnCollect,
      canZeroOnCollect: s.canZeroOnCollect,
      hasX4Mode: s.hasX4Mode,
      x4Mode: s.x4Mode,
      calibration: s.calibration,
      dataMode: s.dataMode,
    };
  });

  out.interfaces = sensorWorld.interfaces;
  out.ignoreSensors = sensorWorld.ignoreSensors;
  out.softSensorIds = sensorWorld.syncableSoftSensorIds;

  return out;
};

export const importSensorWorld = async (sensorWorld, state) => {
  state.sensors.forEach(async sensor => {
    sensorWorldEventHandlers['sensor-added'].call(sensorWorld, sensor);
    await nextTick(1);
    sensorWorldEventHandlers['sensor-ready'].call(sensorWorld, sensor);
    await nextTick(1);
  });

  sensorWorld.interfaces = state.interfaces;
  sensorWorld.syncableSoftSensorIds = state.softSensorIds;
  sensorWorld.ignoreSensors = state.ignoreSensors;

  await nextTick(10); // allows events to be processed
};
