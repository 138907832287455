import { handlerData } from '@services/devicemanager/DeviceManager.handlers.js';
import { nextTick } from '@utils/nextTick.js';

export const exportDeviceManager = deviceManager => {
  const out = {};
  out.usbDeviceList = deviceManager.usbDeviceList;
  out.bluetoothDeviceList = deviceManager.bluetoothDeviceList;
  out.bluetoothAvailable = deviceManager.bluetoothAvailable;
  out.bluetoothIsDiscovering = deviceManager.bluetoothIsDiscovering;
  out.bluetoothDiscoveryMode = deviceManager.bluetoothDiscoveryMode;
  return out;
};

export const importDeviceManager = async (deviceManager, state) => {
  const { eventHandlers, priv } = handlerData;

  deviceManager[priv].bluetoothIsDiscovering = state.bluetoothIsDiscovering;
  deviceManager[priv].bluetoothDiscoveryMode = state.bluetoothDiscoveryMode;

  eventHandlers['devmgr:bluetooth-available'].call(deviceManager, {
    available: state.bluetoothAvailable,
  });
  await nextTick(1);

  state.bluetoothDeviceList.forEach(async d => {
    eventHandlers['devmgr:bluetooth-device-discovered'].call(deviceManager, d);
  });
  await nextTick(1);

  state.usbDeviceList.forEach(async d => {
    eventHandlers['devmgr:usb-device-added'].call(deviceManager, d);
  });

  await nextTick(10); // allows events to be processed
};
