/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
/* eslint func-names: ["error", "as-needed"] */

import { getText } from '@utils/i18n.js';
import { getPointSymbolString } from '@utils/pointSymbolEnumHelpers.js';

const verboseLogging = false;
const logValueUpdates = false; // log all data value updates

const checkUpdateSpecColumnGroup = (params, dataWorld, sensorWorld, dataCollection) => {
  // for spectrometer, change event column group name to 'Concentration' and change units
  const firstSensor = sensorWorld.getFirstSensor();
  const isSpecConnected = firstSensor && !firstSensor?.dataMode.toLowerCase().indexOf('spectrum');
  if (isSpecConnected && params.type === 'event' && dataCollection.spectrumMode !== 'intensity') {
    dataWorld.updateColumnGroup(params.id, {
      name: getText('Concentration', 'sensormap', 'Spec base column name'),
      units: getText('mol/L', 'sensormap', 'Spec Concentration base column name unit'),
    });
  }
};

export const eventHandlers = {
  'dw:data-set-added': function (params) {
    if (verboseLogging) {
      console.log(`-- Data Set Added id=${params.id}--`);
      console.dir(params);
      console.log('--------------------');
    }

    if (params.hasOwnProperty('id')) {
      if (typeof params.id !== 'string') {
        params.id = `${params.id}`;
      }

      this.createInternalDataSet(params);
    }
  },

  'dw:data-set-ready': function (params) {
    if (verboseLogging) {
      console.log(`-- Data Set Ready id=${params.id}--`);
      console.dir(params);
      console.log('--------------------');
    }

    if (params.hasOwnProperty('id')) {
      if (typeof params.id !== 'string') {
        params.id = `${params.id}`;
      }

      const dataSet = this.getAllDataSets().find(ds => ds.id === params.id);
      this.emit('data-set-ready', dataSet);
    }
  },

  'dw:data-set-removed': function (params) {
    if (verboseLogging) {
      console.log(`-- Data Set Removed id=${params.id}--`);
      console.dir(params);
      console.log('--------------------');
    }

    if (params.hasOwnProperty('id')) {
      if (typeof params.id !== 'string') {
        params.id = `${params.id}`;
      }
      this.removeInternalDataSet(params.id);
    }
  },

  'dw:data-column-added': function (params) {
    if (verboseLogging) {
      console.log('-- Data Column Added id=%d, groupId=%d --', params.id, params.groupId);
      console.dir(params);
      console.log('--------------------');
    }

    if (params.hasOwnProperty('id')) {
      if (typeof params.id !== 'string') {
        params.id = `${params.id}`;
      }

      const colProps = {};
      colProps.id = params.id;
      colProps.context = params.context;
      colProps.frozen = params.frozen;

      if (params.hasOwnProperty('type') && params.hasOwnProperty('dataSetId')) {
        colProps.setId = params.dataSetId;
        colProps.groupId = params.groupId;
        colProps.meterId = params.meterId;
        colProps.metered = params.metered;
        colProps.plotted = params.plotted;
        colProps.smaxy = params.SMAXY;
        colProps.editable = params.editable;
        colProps.prefersBase = params.base;
        colProps.precision = params.precision;
        colProps.dependentColumns = params.dependentColumns;
        colProps.replaceDependent = params.replaceDependent;
        colProps.getGroupFn = col => this.getColumnGroupById(col.groupId);
        colProps.getDataSetFn = col => this.dataSets.find(ds => ds.id === col.setId);
        colProps.getSpecialFn = col => {
          const dataSet = this.getDataSetByID(col.setId);
          if (dataSet) {
            return dataSet.type !== 'regular';
          }

          return false;
        };
        colProps.foreignId = params.foreignId;
        colProps.foreignGroupId = params.foreignGroupId;
        colProps.formatStr = params.formatStr;
        colProps.units = params.units;
        colProps.struckRows = params.struckRows;

        colProps.siblingId = params.siblingId;
        colProps.beforeSibling = params.beforeSibling;
        colProps.direction = params.direction;
        colProps.objectId = params.objectId;
        if (colProps.objectId > 0) {
          colProps.type = params.type;
        }

        // Only respect the backend assigned color on import
        // if we are not importing, we need to let the graph
        // to assign it, see RM3846
        if (this.importing) {
          colProps.color = params.color;
          colProps.symbol = getPointSymbolString(params.symbol);
        }

        if (params.scaleMode === 'autoscale_from_zero') {
          colProps.autoscaleFromZero = true;
        }

        if (params.type === 'sensor') {
          colProps.sensorId = params.sensorId;
        } else if (params.type === 'time' && this.timeUnits) {
          // TODO: We're overwritting the default values because we don't have change units but we needs timeUnit changes as we had previous to the DataStore refactor
          colProps.units = this.timeUnits;
          colProps.formatStr = this._getTimeFormatStr(this.timeUnits, this.dataCollection); // DEPRECATED
        }

        colProps.dataType = params.dataType;
      }

      const ds = this.getDataSetByID(params.dataSetId);
      console.assert(ds);
      this.createInternalColumnForDataSet(ds, colProps);
    }
  },

  'dw:data-column-removed': function (params) {
    if (verboseLogging) {
      console.log('-- Data Column Removed id=%d --', params.id);
      console.dir(params);
      console.log('--------------------');
    }

    if (params.hasOwnProperty('id')) {
      const columnId = params.id;
      // Don't log errors if we're in a Data Share session. We now forcibly
      // remove column groups from the back-end which results in this message
      // being thrown (MEG-2106).
      if (!this.removeInternalColumn(columnId) && this.sessionType !== 'DataShare') {
        console.error(`Failed to remove column id= ${columnId}`);
      }
    }
  },

  'dw:data-column-updated': function (params) {
    if (verboseLogging) {
      console.log('-- DataColumn Updated --');
      console.dir(params);
      console.log('--------------------');
    }

    const column = this.getColumnById(params.id);
    if (column) {
      column.range = params.range;
      // Don't change a color if we're in a data share session, otherwise
      // we'll lose the color from the HOST.
      if (params.color && this.sessionType !== 'DataShare') {
        column.color = params.color;
      }
      if (params.dataType) {
        if (params.dataType !== column.dataType) {
          column.dataType = params.dataType;
          this.emit('column-datatype-changed', column);
        }
      }
      column.frozen = params.frozen ?? false;
    } else {
      console.error(`Failed to find column for columnId=${params.id}`);
    }
  },

  'dw:data-set-updated': function (params) {
    if (verboseLogging) {
      console.log('-- DataSet Updated --');
      console.dir(params);
      console.log('--------------------');
    }

    if (!params.id) {
      console.warn('Invalid params');
    } else {
      const dataSet = this.getDataSetByID(params.id);

      if (params.name && params.name !== dataSet.name) {
        dataSet.name = params.name;
        this.emit('dataset-name-changed', dataSet, dataSet.name);
      }
    }
  },

  // this event occurs after a unit change on a column and the values have been flushed to the JS-side code
  'dw:unit-change-finished': function (params) {
    if (verboseLogging) {
      console.log('-- DataColumn Unit-Change Finished --');
      console.dir(params);
      console.log('--------------------');
    }

    const column = this.getColumnById(params.columnId);
    if (column && column.group) {
      this.emit('column-group-unit-change-finished', column.group);
    } else {
      console.error(`Failed to find column group for columnId ${params.columnId}`);
    }
  },

  'dw:data-set-row-added': function (params) {
    if (logValueUpdates) {
      console.log('-- Data Set Row(s) Added --');
      console.dir(params);
      console.log('--------------------');
    }

    params.forEach(update => {
      const { dataSetId } = update;
      const { rowIndex } = update;
      const { count } = update;

      // add a new cell to each column in the dataset for the new row(s)
      const dataSet = this.getDataSetByID(dataSetId);
      if (dataSet) {
        dataSet.columnIds.forEach(columnId => {
          const column = this.getColumnById(columnId);
          const { values } = column;

          // Manual values originate at the FE, so we ignore them, but obviously not during
          // the import stage
          if (!this.importing && (column.type === 'manual' || column.type === 'event')) {
            return;
          }

          let i;
          for (i = 0; i < count; ++i) {
            const index = rowIndex + i;

            if (index === values.length) {
              values[index] = NaN;
            } else if (values instanceof Float64Array) {
              values.copyWithin(index + 1, index);
              values[index] = NaN;
            } else {
              values.splice(index, 0, NaN);
            }
          }
        });
      } else {
        console.error(`Failed to find dataSet for dataSetId= ${dataSetId}`);
      }
    });
  },

  'dw:data-column-values-updated': function (params) {
    if (logValueUpdates) {
      console.log('-- Data Column Values Updated --');
      console.dir(params);
      console.log('--------------------');
    }

    params.forEach(update => {
      const column = this.getColumnById(update.columnId);

      if (column) {
        const { rows, values, wholeColumnFlag } = update;
        const length = Math.min(rows.length, values.length);
        console.assert(rows.length === values.length);

        if (wholeColumnFlag) {
          column.values = [];
          column.liveValue = null;
        }
        column.setValuesByRow(rows, values); // assign new values to column

        // signal that we've changed the underlying values
        if (length > 0) {
          let liveValue = values[values.length - 1];

          // look for the last valid value, if there is one, to be the liveValue.
          // Note the use of `== null` and `!= null` syntax which checks for
          // both null and undefined.
          if (liveValue == null || Number.isNaN(liveValue)) {
            for (let i = values.length - 2; i >= 0; --i) {
              if (values[i] != null && !Number.isNaN(values[i])) {
                liveValue = values[i];
                break;
              }
            }
          }

          if (liveValue != null && !Number.isNaN(liveValue)) {
            column.liveValue = liveValue; // update live value here
          }
          column.emit('values-changed', column.values);
        }
      } else {
        console.error(`Failed to find column for columnId=${update.columnId}`);
      }
    });
  },

  'dw:data-column-live-readout-changed': function (params) {
    if (logValueUpdates) {
      console.log('-- Data Column Live Readout Changed --');
      console.dir(params);
      console.log('--------------------');
    }

    const { columnId } = params;
    const column = this.getColumnById(columnId);
    if (column) {
      const sw = this.sensorWorld;
      const liveValue = params.valid ? params.value : null;
      column.liveValue = liveValue;

      // if this is a sensor column, also update the sensor object's live value
      if (column.type === 'sensor') {
        const sensor = sw.getSensorById(column.sensorId);

        if (sensor) {
          sensor.liveValue = column.liveValue;

          // request system to keep app awake while spectrometer live readouts are active
          if (sensor.dataMode.toLowerCase().includes('spectrum')) {
            if (this.keepAwakeTimeout) {
              clearTimeout(this.keepAwakeTimeout);
            } else {
              this.power.requestWakeLock('system');
            }

            this.keepAwakeTimeout = setTimeout(() => {
              this.power.releaseWakeLock();
            }, 10000);
          }
        } else {
          console.warn(`Failed to find sensor for sensorId=${column.sensorId}`);
        }
      }
    } else {
      console.error(`Failed to find column for columnId=${columnId}`);
    }
  },

  'dw:data-graph-updated': function (params) {
    if (verboseLogging) {
      console.log('-- Graph Updated --');
      console.dir(params);
      console.log('--------------------');
    }

    // The native API uses the 'type' prop to indicate bars rather than the 'bars' prop
    const isBarChart = params.type === 'bar-chart';
    if (isBarChart) params.type = 'normal';
    params.bars = isBarChart;

    this._graphInfos[params.graphId] = params;

    this.emit('imported-graph-state-ready', params);
  },

  'dw:data-annotation-updated': function (params) {
    if (verboseLogging) {
      console.log('-- Annotation Updated --');
      console.dir(params);
      console.log('--------------------');
    }

    this.emit('annotation-updated', params);
  },

  'dw:data-video-updated': function (params) {
    if (verboseLogging) {
      console.log('-- Video Updated --');
      console.dir(params);
      console.log('--------------------');
    }

    this.emit('imported-video-state-ready', params);
  },

  // Data/Column Group Objects
  'dw:data-group-added': function (params) {
    if (verboseLogging) {
      console.log('-- Data Group Added name=%s, id=%d --', params.name, params.id);
      console.dir(params);
      console.log(`precision: ${params.precision.precision}`);
      console.log('--------------------');
    }

    // update backend with translated column group names
    const stringCtx = params.name === 'Time' ? 'general' : 'sensormap';
    const translatedName = getText(params.name, stringCtx);
    if (translatedName !== params.name) {
      const args = {
        experimentId: this.experimentId,
        groupId: params.id,
        properties: { name: translatedName },
      };
      this.api.updateColumnGroup(args);
    }

    const groupProps = {};
    groupProps.id = params.id;

    groupProps.name = params.name;
    groupProps.units = params.units;
    groupProps.color = params.color !== 'auto' ? params.color : '';
    groupProps.direction = params.direction;
    groupProps.precision = params.precision;
    groupProps.automaticPrecision = params.automaticPrecision;

    groupProps.calcEquation = params.calcEquation;
    groupProps.customEq = params.calcEquationStr;
    groupProps.calcDependentGroups = params.calcDependentGroups.map(colId => `${colId}`);
    groupProps.calcCoefficients = params.calcCoefficients;
    groupProps.calcUserEditable = params.calcUserEditable;
    groupProps.calcCustomEqNeedsParsing = params.calcCustomEqNeedsParsing;
    groupProps.errorBarColumnId = params.errorBarColumnId;
    groupProps.errorBarType = params.errorBarType;
    groupProps.errorBarValue = params.errorBarValue;

    groupProps.type = params.type;
    groupProps.range = params.range;

    groupProps.replaceDependent = params.replaceDependent;

    groupProps.editable = params.editable;
    groupProps.deletable = params.deletable;

    groupProps.sensorId = params.sensorId;
    groupProps.sensorMapId = params.sensorMapId;
    groupProps.wavelength = params.wavelength;

    groupProps.meterId = params.meterId;
    groupProps.metered = params.metered;
    groupProps.smaxy = params.SMAXY;

    groupProps.plotted = params.plotted;
    groupProps.prefersBase = params.base;
    groupProps.getAllColumnsFn = group =>
      this.getAllColumns().filter(col => col.groupId === group.id);

    groupProps.siblingId = params.siblingId;
    groupProps.beforeSibling = params.beforeSibling;

    if (params.scaleMode === 'autoscale_from_zero') {
      groupProps.autoscaleFromZero = true;
    }

    this.createInternalGroup(groupProps);

    if (this.session) {
      checkUpdateSpecColumnGroup(params, this, this.sensorWorld, this.dataCollection);
    } else {
      this.once(
        'session-started',
        () => checkUpdateSpecColumnGroup(params, this, this.sensorWorld, this.dataCollection),
        this,
      );
    }
  },

  'dw:data-group-removed': function (params) {
    if (verboseLogging) {
      console.log('-- Data Group Removed id=%d --', params.id);
      console.dir(params);
      console.log('--------------------');
    }

    this.removeInternalGroup(params.id);
  },

  'dw:data-group-properties-changed': function (params) {
    if (verboseLogging) {
      console.log('-- Data Group Properties Changed id=%d --', params.id);
      console.dir(params);
      console.log('--------------------');
    }

    // update backend with translated column group names
    const stringCtx = params.name === 'Time' ? 'general' : 'sensormap';
    const translatedName = getText(params.name, stringCtx);
    if (translatedName !== params.name) {
      const args = {
        experimentId: this.experimentId,
        groupId: params.id,
        properties: { name: translatedName },
      };
      this.api.updateColumnGroup(args);
    }

    // time is not editable so it just needs to follow along
    // with data collection settings for now.
    if (params.type === 'time') {
      params.units = this.timeUnits;
    }

    const { id } = params;
    const groupProps = {};

    groupProps.name = params.name;
    groupProps.units = params.units;
    groupProps.color = params.color !== 'auto' ? params.color : '';

    groupProps.precision = params.precision;
    groupProps.automaticPrecision = params.automaticPrecision;

    groupProps.calcEquation = params.calcEquation;
    groupProps.customEq = params.calcEquationStr;
    groupProps.calcDependentGroups = params.calcDependentGroups;
    groupProps.calcCoefficients = params.calcCoefficients;
    groupProps.calcUserEditable = params.calcUserEditable;
    groupProps.calcCustomEqNeedsParsing = params.calcCustomEqNeedsParsing;

    groupProps.errorBarColumnId = params.errorBarColumnId;
    groupProps.errorBarType = params.errorBarType;
    groupProps.errorBarValue = params.errorBarValue;

    groupProps.type = params.type;
    groupProps.range = params.range;

    groupProps.replaceDependent = params.replaceDependent;

    groupProps.editable = params.editable;
    groupProps.deletable = params.deletable;

    groupProps.sensorId = params.sensorId;
    groupProps.sensorMapId = params.sensorMapId;
    groupProps.wavelength = params.wavelength;

    groupProps.metered = params.metered;
    groupProps.plotted = params.plotted;
    groupProps.prefersBase = params.base;
    groupProps.direction = params.direction;

    groupProps.smaxy = params.SMAXY;

    if (params.scaleMode === 'autoscale_from_zero') {
      groupProps.autoscaleFromZero = true;
    }

    if (verboseLogging) {
      console.log(`Name: ${params.name}`);
      console.log(`SMAXY: ${params.SMAXY}`);
    }

    this.updateInternalGroup(id, groupProps);
  },

  'dw:session-started': function (params) {
    if (verboseLogging) {
      console.log('-- Session Started --');
      console.dir(params);
      console.log('--------------------');
    }

    this.sessionStarted(params);
  },

  'dw:document-age-updated': function (params) {
    this.emit('dw:document-age-updated', params);
  },

  'gc-collection-started': function () {
    this.isCollecting = true;
    this.emit('gc-collection-started');
  },

  'collection-started': function () {
    this._notifyCollectionStarted();
  },
  // TODO: add dw prefix?
  // collection-ended event is emitted when collection completes,
  // regardless of the reason
  'collection-ended': function () {
    if (this.isCollecting) {
      this._notifyCollectionStopped();
    }
  },

  // complete-collection event is emitted on normal completion of
  // collection
  'complete-collection': function () {
    // This is strictly unnecessary, as the collection would be stopped
    // anyway on collection-ended, but this event fires earlier, so we might
    // just as well update the UI straight away.
    if (this.isCollecting) {
      // TODO: replace with _notifyCollectionStopped() call when back-end no longer requires stopCollection() call
      this.stopCollection();
    }
  },

  'trigger-reached': function () {
    this.emit('collection-threshold-reached');
  },

  'prestore-reached': function () {
    this.emit('collection-prestore-reached');
  },

  'replay-engine-update': function (params) {
    this.emit('replay-engine-update', params);
  },

  'dw:strikethrough-changed': function (params) {
    const { experimentId, dataSetId, columnId, struckRows } = params;

    if (verboseLogging) {
      console.log(
        `-- Strikethrough Rows Changed: experimentId=${experimentId}, dataSetId=${dataSetId} columnId=${columnId}`,
      );
      console.dir(struckRows);
    }

    const column = this.getColumnById(columnId);
    if (column) column.struckRows = struckRows;
  },

  'rpc-time-warning': function (params) {
    this.emit('rpc-time-warning', params);
  },
};
