import EventEmitter from 'eventemitter3';
import { isEqual, toPairs, kebabCase } from 'lodash-es';

import { bluetoothState } from '@services/devicemanager/bluetoothState.js';

const priv = Symbol('priv');

// Events:
//
// "name-changed": string name
// "display-name-changed": string displayName,
// "serial-name-changed": string serialNumber,
// "address-changed": string address,
// "order-code-changed": string orderCode,
// "gdxmap-version-changed": object gdxMapVersion,
// "firmware-changed": object firmware,
// "channels-changed": channels,
// "connectable-changed": bool
// "state-changed": state,
// "battery-changed", int
// "can-identify-changed": bool,
// "error-message-changed": error

export class Device extends EventEmitter {
  constructor(
    type = '',
    {
      id = -1,
      name = '',
      displayName = '',
      serialNumber = '',
      address = '',
      orderCode = '',
      firmware = {},
      gdxMapVersion = 0,
      channels = [],
      state = '',
      battery = {},
      canIdentify = false,
      errorMessage = null,
      calibrationProcesses = null,
      manufactureDate = '',
      userCalibrationDate = '',
      factoryCalibrationDate = '',
      hasUserCalibration = false,
      usingUserCalibration = false,
      hasFactoryCalibration = false,
      usingFactoryCalibration = false,
      hasDeviceAttributes = false,
      deviceAttributes = null,
      // TODO: Spec specific properties shouldn't live here long term.
      spectraTransportTime = 0,
      spectraTimeScale = 0,
      minCollectionDelta = 0,
      maxCollectionDelta = 0,
      minWavelength = 0,
      maxWavelength = 0,
      minWavelengthSmoothing = 0,
      maxWavelengthSmoothing = 0,
      wavelengthSmoothing = 0,
      minTemporalAveraging = 0,
      maxTemporalAveraging = 0,
      temporalAveraging = 0,
      initialWarmupTime = 0,
      recalibrationTime = 0,
      noSkipTime = 0,
      calibrated = false,
      integrationTime = 0,
      spectrumMode = 'absorbance',
      supportedSpectrumModes = [],
      supportedLedWavelengths = [],
      ledWavelength = 0,
      ledIntensity = 0,
      ledCustomWavelengthSupport = false,
      supportsFiniteCollections = false,
      numberOfLoggedRuns = 0,
      offlineLoggingStatus = {},
      gdxCartFanThrustVal = 0,
      isGdxCartFanAlwaysEnabled = false,
      gcProfileRanges = null,
      gcDefaultProfile = null,
      cvDefaultProfile = null,
      beDefaultProfile = null,
      ocpDefaultProfile = null,
      cvsProfileRanges = null,
      cvsElectrodeType = null,
      hasUserConfigurableChannels = false,
      lotCode = '',
      isLegacy = false,
    } = {},
  ) {
    super();
    this[priv] = {
      type,
      id,
      name,
      displayName,
      serialNumber,
      address,
      orderCode,
      firmware,
      channels,
      gdxMapVersion,
      state,
      battery,
      canIdentify,
      errorMessage,
      calibrationProcesses,
      manufactureDate,
      userCalibrationDate,
      factoryCalibrationDate,
      hasUserCalibration,
      usingUserCalibration,
      hasFactoryCalibration,
      usingFactoryCalibration,
      hasDeviceAttributes,
      deviceAttributes,
      minCollectionDelta,
      maxCollectionDelta,
      spectraTransportTime,
      spectraTimeScale,
      minWavelength,
      maxWavelength,
      minWavelengthSmoothing,
      maxWavelengthSmoothing,
      wavelengthSmoothing,
      minTemporalAveraging,
      maxTemporalAveraging,
      temporalAveraging,
      initialWarmupTime,
      recalibrationTime,
      noSkipTime,
      calibrated,
      integrationTime,
      spectrumMode,
      supportedSpectrumModes,
      supportedLedWavelengths,
      ledWavelength,
      ledIntensity,
      ledCustomWavelengthSupport,
      supportsFiniteCollections,
      numberOfLoggedRuns,
      offlineLoggingStatus,
      gdxCartFanThrustVal,
      isGdxCartFanAlwaysEnabled,
      gcProfileRanges,
      gcDefaultProfile,
      cvDefaultProfile,
      beDefaultProfile,
      ocpDefaultProfile,
      cvsProfileRanges,
      cvsElectrodeType,
      hasUserConfigurableChannels,
      lotCode,
      isLegacy,
    };
  }

  get enabledChannels() {
    return this[priv].channels.filter(channel => channel.enabled);
  }

  get type() {
    return this[priv].type;
  }

  get id() {
    return this[priv].id;
  }

  get deviceName() {
    return this[priv].name;
  }

  get name() {
    return this[priv].name;
  }

  get productName() {
    return this[priv].displayName;
  }

  get displayName() {
    return this[priv].displayName;
  }

  get serialNumber() {
    return this[priv].serialNumber;
  }

  get address() {
    return this[priv].address;
  }

  get orderCode() {
    return this[priv].orderCode;
  }

  get firmware() {
    return this[priv].firmware;
  }

  get gdxMapVersion() {
    return this[priv].gdxMapVersion;
  }

  get channels() {
    return this[priv].channels;
  }

  get connected() {
    return this[priv].state === 'connected'; // disconnected, connecting, connected, error
  }

  get state() {
    return this[priv].state; // disconnected, connecting, connected, error
  }

  get battery() {
    this[priv].battery.percent =
      this[priv].battery.percent >= 0 ? this[priv].battery.percent : undefined;
    return this[priv].battery;
  }

  get canIdentify() {
    return this[priv].canIdentify;
  }

  get errorMessage() {
    return this[priv].errorMessage;
  }

  get calibrationProcesses() {
    return this[priv].calibrationProcesses;
  }

  get manufactureDate() {
    return this[priv].manufactureDate;
  }

  get userCalibrationDate() {
    return this[priv].userCalibrationDate;
  }

  get factoryCalibrationDate() {
    return this[priv].factoryCalibrationDate;
  }

  get hasUserCalibration() {
    return this[priv].hasUserCalibration;
  }

  get usingUserCalibration() {
    return this[priv].usingUserCalibration;
  }

  get hasFactoryCalibration() {
    return this[priv].hasFactoryCalibration;
  }

  get usingFactoryCalibration() {
    return this[priv].usingFactoryCalibration;
  }

  get calibrated() {
    return this[priv].calibrated;
  }

  get hasDeviceAttributes() {
    return this[priv].hasDeviceAttributes;
  }

  get deviceAttributes() {
    return this[priv].deviceAttributes;
  }

  get spectrumMode() {
    return this[priv].spectrumMode;
  }

  get supportedSpectrumModes() {
    return this[priv].supportedSpectrumModes;
  }

  get supportedLedWavelengths() {
    return this[priv].supportedLedWavelengths;
  }

  get ledWavelength() {
    return this[priv].ledWavelength;
  }

  get ledIntensity() {
    return this[priv].ledIntensity;
  }

  get ledCustomWavelengthSupport() {
    return this[priv].ledCustomWavelengthSupport;
  }

  get integrationTime() {
    return this[priv].integrationTime;
  }

  get spectraTimeScale() {
    return this[priv].spectraTimeScale;
  }

  get spectraTransportTime() {
    return this[priv].spectraTransportTime;
  }

  get minCollectionDelta() {
    return this[priv].minCollectionDelta;
  }

  get maxCollectionDelta() {
    return this[priv].maxCollectionDelta;
  }

  get minWavelength() {
    return this[priv].minWavelength;
  }

  get maxWavelength() {
    return this[priv].maxWavelength;
  }

  get minWavelengthSmoothing() {
    return this[priv].minWavelengthSmoothing;
  }

  get maxWavelengthSmoothing() {
    return this[priv].maxWavelengthSmoothing;
  }

  get wavelengthSmoothing() {
    return this[priv].wavelengthSmoothing;
  }

  get minTemporalAveraging() {
    return this[priv].minTemporalAveraging;
  }

  get maxTemporalAveraging() {
    return this[priv].maxTemporalAveraging;
  }

  get temporalAveraging() {
    return this[priv].temporalAveraging;
  }

  get initialWarmupTime() {
    return this[priv].initialWarmupTime;
  }

  get recalibrationTime() {
    return this[priv].recalibrationTime;
  }

  get noSkipTime() {
    return this[priv].noSkipTime;
  }

  get offlineLoggingStatus() {
    return this[priv].offlineLoggingStatus;
  }

  get supportsOfflineCollection() {
    return Boolean(this[priv].offlineLoggingStatus);
  }

  get supportsFiniteCollections() {
    return this[priv].supportsFiniteCollections;
  }

  get numberOfLoggedRuns() {
    return this[priv].numberOfLoggedRuns;
  }

  get gdxCartFanThrustVal() {
    return this[priv].gdxCartFanThrustVal;
  }

  get isGdxCartFanAlwaysEnabled() {
    return this[priv].isGdxCartFanAlwaysEnabled;
  }

  get isGdxDevice() {
    return this[priv].name.startsWith('GDX-');
  }

  get isGcDevice() {
    const { name } = this[priv];
    return name.startsWith('Mini GC') || name.startsWith('GDX-GC') || name.startsWith('GC_');
  }

  get isGdxCvs() {
    return this[priv].name.startsWith('GDX-CVS');
  }

  get isGdxPol() {
    return this[priv].name.startsWith('GDX-POL');
  }

  get gcProfileRanges() {
    return { ...this[priv].gcProfileRanges };
  }

  get gcDefaultProfile() {
    return { ...this[priv].gcDefaultProfile };
  }

  get cvsProfileRanges() {
    return { ...this[priv].cvsProfileRanges };
  }

  get cvDefaultProfile() {
    return { ...this[priv].cvDefaultProfile };
  }

  get beDefaultProfile() {
    return { ...this[priv].beDefaultProfile };
  }

  get ocpDefaultProfile() {
    return { ...this[priv].ocpDefaultProfile };
  }

  get cvsElectrodeType() {
    return this[priv].cvsElectrodeType;
  }

  get hasUserConfigurableChannels() {
    return this[priv].hasUserConfigurableChannels;
  }

  get lotCode() {
    return this[priv].lotCode;
  }

  get isLegacy() {
    return this[priv].isLegacy;
  }

  _update(props = {}) {
    const propMap = {};

    Object.keys(this[priv]).forEach(key => {
      propMap[key] = `${kebabCase(key)}-changed`;
    });

    toPairs(propMap).forEach(([prop, propEvent]) => {
      const newValue = props[prop];
      const currentValue = this[priv][prop];
      if (prop in props && !isEqual(newValue, currentValue)) {
        this[priv][prop] = newValue;
        this.emit(propEvent, newValue);
      }
    });

    if (this[priv].state !== bluetoothState.error) {
      // TODO: rename bluetoothState
      this[priv].errorMessage = null;
    }
  }

  updateCalibrationStep(props = {}) {
    this.emit('calibration-step-result-changed', props);
  }
}
