import * as platform from './PLATFORM_ID';

let wakeLock = null;

async function requestWakeLock(level) {
  if (platform.requestWakeLock) {
    return platform.requestWakeLock(level);
  }

  try {
    wakeLock = await navigator.wakeLock.requestWakeLock('screen'); // screen is the only "level" on the web so far.

    wakeLock.addEventListener('release', () => {
      wakeLock = null;
    });
  } catch (error) {
    console.warn(error.message);
  }
  return true;
}

function releaseWakeLock() {
  if (platform.releaseWakeLock) {
    return platform.releaseWakeLock();
  }

  if (wakeLock)
    wakeLock.release().then(() => {
      wakeLock = null;
    });
  return true;
}

export const createWakelock = () => ({
  requestWakeLock,
  releaseWakeLock,
});
