import { observable, action, makeObservable, runInAction, toJS } from 'mobx';
import EventEmitter from 'eventemitter3';

import { Sensor } from '@api/common/Sensor.js';
import { TestSensor } from '@services/sensorworld/TestSensor.js';

import { getText } from '@utils/i18n.js';

import { eventHandlers } from '@services/sensorworld/SensorWorld.handlers.js';

const INVALID_SENSOR_ID = 0;

// Events:

// 'device-attached'
// 'device-detached'

// 'sensor-added'
// 'sensor-removed'

// 'sensor-offline'

// CONSTRUCTOR
export class SensorWorld extends EventEmitter {
  constructor(config) {
    super();

    // TODO: add property to the Sensor class specifying whether a sensor is a soft or real to avoid needing this
    this.softSensorIds = [];

    makeObservable(this, {
      softSensorIds: observable,
      addSoftSensor: action,
      removeSoftSensor: action,
    });

    this.dataCollection = config.dataCollection;
    this.sensorMap = config.sensorMap;
    this.api = config.api;
    Object.keys(eventHandlers).forEach(key => this.api.on(key, eventHandlers[key].bind(this)));

    this._sensors = [];
    this.interfaces = [];
    this.ignoreSensors = false;

    // MEG-22: In order to translate column names that are coming out of native-modules, we need to have duplicate
    // definitions of the exact strings in the front-end code so getText can find them.
    //
    // TODO: This should be revisited if/when we get rid of getText.
    this._nativeModulesColumnNames = [
      getText(
        'Absorbance',
        'sensormap',
        'Spec column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Transmittance',
        'sensormap',
        'Spec column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Intensity',
        'sensormap',
        'Spec column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Fluorescence',
        'sensormap',
        'Spec column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Raw',
        'sensormap',
        'Spec column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Distance',
        'sensormap',
        'Photogate column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Velocity',
        'sensormap',
        'Photogate column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Gate Time',
        'sensormap',
        'Photogate column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Pulse Time',
        'sensormap',
        'Photogate column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Period',
        'sensormap',
        'Photogate column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Launch Speed',
        'sensormap',
        'Photogate column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
      getText(
        'Time of Flight',
        'sensormap',
        'Photogate column name [vst-native-modules/src/sensorworld/SpecIOChannel.cpp]',
      ),
    ];

    Sensor.sensorWorld = this;

    this.dataCollection.setSensorWorld(this); // HACK to workaround circular dependency
  }

  get sensors() {
    return this._sensors;
  }

  get syncableSoftSensorIds() {
    return toJS(this.softSensorIds);
  }

  set syncableSoftSensorIds(value) {
    runInAction(() => {
      this.softSensorIds = value;
    });
  }

  createInternalSensor(props, suppressEvent) {
    const sensor = new Sensor(props);
    this._sensors.push(sensor);
    if (!suppressEvent) this.emit('sensor-added', sensor);
    return sensor;
  }

  removeInternalSensor(id, suppressEvent) {
    const sensor = this._sensors.find(sensor => sensor.id === id);
    if (sensor) {
      this._sensors = this._sensors.filter(sensor => sensor.id !== id);
      if (!suppressEvent) this.emit('sensor-removed', sensor);
    }
  }

  clearExperiment(experimentId) {
    this._sensors.forEach(s => {
      if (s.experimentId === experimentId) {
        this.removeInternalSensor(s.id, false);
      }
    });
  }

  _setSensorOffline(uniqueId) {
    const sensor = this._sensors.find(s => s.id === uniqueId);

    // The sensor is no longer connected to a device so the calibration processes are no longer valid
    if (sensor) {
      sensor.clearCalibrationProcesses();
      this.emit('sensor-offline', sensor);
    }
  }

  getSensorById(id) {
    return this.sensors.find(sensor => sensor.id === id);
  }

  isSensorOnline(id) {
    return !!this.getSensorById(id);
  }

  getFirstSensor() {
    return this.sensors[0];
  }

  // config.autoId:
  addTestSensor(config) {
    const sensor = new TestSensor({
      autoId: config.autoId || -1,
    });

    this.createInternalSensor(sensor);
  }

  isRadiationMonitor(sensorId) {
    let sensor;

    if (sensorId !== INVALID_SENSOR_ID) {
      sensor = this.getSensorById(sensorId);
    }

    return !!(sensor && sensor.dataMode === 'Radiation');
  }

  isMotionDetector(sensorId) {
    let sensor;

    if (sensorId !== INVALID_SENSOR_ID) {
      sensor = this.getSensorById(sensorId);
    }

    return !!(sensor && sensor.dataMode === 'Digital Motion');
  }

  isRotationSensor(sensorId) {
    let sensor;

    if (sensorId !== INVALID_SENSOR_ID) {
      sensor = this.getSensorById(sensorId);
    }

    return !!(sensor && sensor.dataMode === 'Rotation');
  }

  setIgnoreAddedSensors(experimentId, ignoreSensors) {
    return this.api.setIgnoreAddedSensors(experimentId, ignoreSensors);
  }

  // collectionConfig.measurementPeriod
  prepareCollection(collectionConfig) {
    const measurementPeriod = collectionConfig.measurementPeriod || 1;

    return this.dataCollection.setMeasurementPeriod(measurementPeriod);
  }

  deviceConnectionChange(deviceInfo) {
    this.emit('device-connection-changed', deviceInfo);
  }

  resetSensorState() {
    this._sensors.forEach(sensor => {
      sensor._calibrationReversed = false;
    });
  }

  changeSensorUnit(experimentId, sensorId, newUnit) {
    console.assert(sensorId);
    return this.api.changeSensorUnit(parseInt(experimentId), parseInt(sensorId), newUnit);
  }

  calculateCalibrationCoeffs(experimentId, sensorId, pairs) {
    // pull the pairs out into two separate arrays for processing by the DC module
    const voltages = new Float64Array(pairs.length);
    const references = new Float64Array(pairs.length);

    pairs.forEach((pair, i) => {
      [voltages[i], references[i]] = pair;
    });

    return this.api.calculateCalibrationCoeffs(experimentId, sensorId, voltages, references);
  }

  setCalibration(experimentId, sensorId, coeffs) {
    return this.api.setCalibration(experimentId, sensorId, coeffs);
  }

  setSelectedWavelength(experimentId, sensorId, wavelength) {
    return this.api.setSelectedWavelength(experimentId, sensorId, wavelength || 0);
  }

  zeroSensor(experimentId, sensorId, curValue) {
    return this.api.zeroSensor(experimentId, sensorId, curValue);
  }

  reverseSensor(experimentId, sensorId) {
    return this.api.reverseSensor(experimentId, sensorId);
  }

  setSensorModeX4(experimentId, sensorId, enable) {
    return this.api.setSensorModeX4(experimentId, sensorId, enable);
  }

  setSensorZeroOnCollect(experimentId, sensorId, enable) {
    return this.api.setSensorZeroOnCollect(experimentId, sensorId, enable);
  }

  takeDarkReference(experimentId, sensorID) {
    return this.api.takeDarkReference(experimentId, sensorID);
  }

  calibrateSpectrometer(experimentId, sensorId) {
    return this.api.calibrateSpectrometer(experimentId, sensorId);
  }

  /**
   * Set spectrometer-specific settings on a device.
   * @param {*} sensorID identifier for the spec device
   * @param {Number} params.integrationTime [required]
   * @param {Number} params.LEDWavelength [required]
   * @param {Number} params.LEDIntensity [required]
   * @param {Number} params.wavelengthSmoothing [required]
   * @param {Number} params.temporalAveraging
   */
  setSpecSettings(experimentId, sensorID, params) {
    return this.api.setSpecSettings(experimentId, sensorID, params);
  }

  /**
   * Add a soft sensor to the soft device, enabling the soft device if necessary
   * @param {Number} sensorTypeId non-zero integer id specifing the sensormap 'auto-id' of the sensor to emulate
   */
  async addSoftSensor(experimentId, sensorTypeId = 1) {
    try {
      if (!this.softSensorIds.length) {
        await this.api.enableSoftDevice(experimentId);
      }

      await this.api.enableSoftSignWaveSensor(experimentId, sensorTypeId);
      runInAction(() => {
        this.softSensorIds = [...this.softSensorIds, sensorTypeId];
      });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Add and explicitely configure soft sensor to the soft device, enabling the soft device if necessary; this API point
   * is primarily for automation.
   * @param {object} params
   * @param {Number} [params.experimentId] the experiment to add the sensor to
   * @param {Number} params.sensorTypeId non-zero integer id specifing the sensormap 'auto-id' of the sensor to emulate
   * @param {Number} [params.frequency] frequency of the sensor sinusoid signal in Hz (
   * @param {Number} [params.amplitude] amplitude of the sensor sinusoid signal in V,
   * @param {Number} [params.offset] offset of the sensor sinusoid signal in V
   * @returns {Promise}
   */
  async addAndConfigureSoftSensor({
    experimentId = 0,
    sensorTypeId,
    frequency = 0.014,
    amplitude = 1,
    offset = 0,
  }) {
    if (!sensorTypeId) {
      console.error(`invalid mandatory argument sensorTypeId (${sensorTypeId})`);
      return;
    }
    try {
      if (!this.softSensorIds.length) {
        await this.api.enableSoftDevice(experimentId);
      }

      await this.api.enableAndConfigureSoftSignWaveSensor(
        experimentId,
        sensorTypeId,
        frequency,
        amplitude,
        offset,
      );
      runInAction(() => {
        this.softSensorIds = [...this.softSensorIds, sensorTypeId];
      });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Remove a soft sensor of the specified type from the soft device, removing the soft device if the last sensor get removed
   * @param {Number} sensorTypeId non-zero integer id specifing the sensormap 'auto-id' of the sensor to emulate
   */
  async removeSoftSensor(experimentId, sensorTypeId = 1) {
    try {
      await this.api.disableSoftSignWaveSensor(experimentId, sensorTypeId);

      runInAction(() => {
        const index = this.softSensorIds.lastIndexOf(sensorTypeId);
        this.softSensorIds.splice(index, 1);
        this.softSensorIds = [...this.softSensorIds]; // ensure that updates trigger :/
      });
      if (!this.softSensorIds.length) {
        await this.api.disableSoftDevice(experimentId);
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * IA and SA apps only
   * Create soft device AND associated soft sensors
   * For IA this will be a soft Gas Chromatograph, for SA this will be a spec
   */
  async enableSpecialSoftSensor(experimentId) {
    await this.api.enableSoftDevice(experimentId);
    return this.api.enableSpecialSoftSensor(experimentId);
  }

  /**
   * IA and SA apps only
   * Change configuration of the soft device
   * For IA this will be a soft Gas Chromatograph, for SA this will be a spec
   *
   * These soft sensors produce identical data on each run, but the scale and offset parameters can be used to alter the
   * amplitude and position of the curve. The levelOuput parameter can be used to force a constant output instead, at
   * the level given by offset.
   *
   * Calling this function will enable the soft device if not already enabled.
   *
   * This API point is intended primarily for automation.
   *
   * @param {object} params
   * @param {Number} [params.experimentId] the experiment to add the sensor to
   * @param {Number} [params.scale] scale factor to apply to the data
   * @param {Number} [params.offset] offset to apply to the data
   * @param {Boolean} [params.levelOutput] whether to produce level output (at level given by offset)
   * @returns {Promise}
   */
  async configureSpecialSoftSensor(
    params = { experimentId: 0, scale: 1, offset: 0, levelOutput: false },
  ) {
    // Make sure the device is enabled; these can be safely called multiple times
    const { experimentId } = params;
    await this.api.enableSoftDevice(experimentId);
    await this.api.enableSpecialSoftSensor(experimentId);
    return this.api.configureSpecialSoftSensor(params);
  }

  /**
   * IA and SA apps only
   * Request the removal of BOTH the soft sensors and the associated soft device
   */
  async disableSpecialSoftSensor(experimentId) {
    await this.api.disableSpecialSoftSensor(experimentId);
    return this.api.disableSoftDevice(experimentId);
  }

  /**
   * Add Soft device
   * @param {String} device order code
   * @returns deviceId
   */
  async addSoft2Device(orderCode) {
    const result = await this.api.addSoft2Device(orderCode);
    return result.deviceId;
  }

  /**
   * Remove Soft device
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @returns {Promise}
   */
  async removeSoft2Device(deviceId) {
    return this.api.removeSoft2Device(deviceId);
  }

  /**
   * Get soft device config
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @returns {Promise}
   */
  async getSoft2DeviceConfig(deviceId) {
    return this.api.getSoft2DeviceConfig(deviceId);
  }

  /**
   * Set soft device config
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @param {Object} config; for documentation on the current config format see SOFT2SENSORS.md in NativeModules
   * @returns {Promise}
   */
  async setSoft2DeviceConfig(deviceId, config) {
    return this.api.setSoft2DeviceConfig(deviceId, config);
  }

  /**
   * Add Soft sensor to a soft Labquest device
   * @param {String} deviceId as returned by addSoft2Device
   * @param {Number} channelNumber where to add the device (1-based)
   * @param {String} or {Number} source - device order code (string) or sensorId (number)
   * @returns {Promise}
   */
  async addSoft2Sensor(deviceId, channelNumber, source) {
    const orderCode = Number.isInteger(source) ? '' : source;
    const sensorId = Number.isInteger(source) ? source : 0;

    return this.api.addSoft2Sensor(deviceId, channelNumber, orderCode, sensorId);
  }

  /**
   * Remove Soft sensor
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @param {Number} channelNumber where to remove the device from (1-based)
   * @returns {Promise}
   */
  async removeSoft2Sensor(deviceId, channelNumber) {
    return this.api.removeSoft2Sensor(deviceId, channelNumber);
  }

  /**
   * Freeze soft device, i.e., temporarily stop the device generating values. This is needed in order to
   * to test flows such as automatic calibration of a drop counter. This function can be called multiple times
   * in succession with different atTimeCount parameter, effectively manually advancing the device value. For
   * example, to calibrate the soft GDX-DC device, we can call this function with atTimeCount 1 prior to
   * opening the calibration dialog, then calling it again with atTimeCount of 10 after opening the dialog
   * will advance the drop count to 10.
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @param {Number} atTimeCount when to freeze device
   * @returns {Promise}
   */
  async freezeSoft2Device(deviceId, atTimeCount) {
    return this.api.freezeSoft2Device(deviceId, atTimeCount);
  }

  /**
   * Unfreeze previously frozen soft device, i.e., make it start generating values again.
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @returns {Promise}
   */
  async unfreezeSoft2Device(deviceId) {
    return this.api.unfreezeSoft2Device(deviceId);
  }

  /**
   * Initialize Soft2Device interface
   * @returns {Promise}
   */
  async initSoft2DeviceInterface() {
    return this.api.initSoft2DeviceInterface();
  }
}
