import { nextTick } from '@common/utils/nextTick';
import { serviceWorkerInitializer } from '@utils/serviceWorker/ServiceWorkerInitializer.js';

/**
 * the base class for all RPC modules
 */
class RPCBase {
  blockResponses = false;

  constructor() {
    navigator.serviceWorker.addEventListener('message', async event => {
      const { type, message } = event.data;

      if (type === 'NATIVE_RESPONSE') {
        await this._hasOnmessage;

        this.checkResponseMessage(message);

        if (!this.blockResponses) {
          this.onmessage(message);
        }
      }
    });
  }

  /** @abstract */
  // eslint-disable-next-line class-methods-use-this
  checkResponseMessage() {}

  /**
   * allow for awaiting the onmessage method to get set, since it gets set outside this class
   */
  get _hasOnmessage() {
    return (
      this.onmessage ??
      (async () => {
        // eslint-disable-next-line no-await-in-loop
        while (!this.onmessage) await nextTick(100);
        return true;
      })()
    );
  }

  /**
   *
   * @param {Object} rawMessage the raw message to be posted to the service worker for routing to the host instance in order to get the message to native modules
   */
  // eslint-disable-next-line class-methods-use-this
  postMessage(message) {
    serviceWorkerInitializer.joinedSession.then(activeServiceWorker => {
      activeServiceWorker.postMessage({
        type: 'NATIVE_MESSAGE',
        message,
      });
    });
  }
}

/**
 * the host RPC instance
 */
class RPCService extends RPCBase {
  constructor() {
    super();
    navigator.serviceWorker.addEventListener('message', event => {
      const { type, message } = event.data;
      if (type === 'NATIVE_MESSAGE') {
        this.sendNativeMessage(message);
      }
    });
    if (window.Module) {
      window.Module.onRPCServiceNotify = this.checkMessage.bind(this);
    }
  }

  /**
   * check for messages from the native module and process
   */
  checkMessage() {
    const message = window.Module.vstBackendPollMsg();
    this._processMessage(message);
  }

  /**
   * process messages from native modules
   * @param {Object} nmMessage the Native Modules message to be processed
   */
  _processMessage(nmMessage) {
    const message = nmMessage?.toJS ? nmMessage.toJS() : nmMessage;
    if (message && this.onmessage) {
      serviceWorkerInitializer.joinedSession.then(activeServiceWorker => {
        activeServiceWorker.postMessage({
          type: 'NATIVE_RESPONSE',
          message,
        });
      });
    }
  }

  // special handling
  _checkGetHostStateMessage(message) {
    let handled = false;

    // handle querying the host state directly and don't
    // send along to native modules
    if (message.method === 'GET_HOST_STATE') {
      console.assert(serviceWorkerInitializer.getHostServiceState);

      const result = {
        id: parseInt(message.id),
        jsonrpc: '2.0',
        result: {
          IS_HOST_SERVICE_STATE: true,
          ...serviceWorkerInitializer.getHostServiceState(),
        },
      };

      this._processMessage(result);
      handled = true;
    } else if (message.method === 'CLIENT_STATE_RESTORED') {
      // special handling for this method, simply ignore
      handled = true;
    }

    return handled;
  }

  /**
   * sends a message to native modules
   * @param {String|import("@services/serviceworkerSession/ServiceworkerSession").MessageData} message the message to be sent to native modules
   */
  sendNativeMessage(message) {
    let target = 0;

    const skipNativeCall = this._checkGetHostStateMessage(message);

    if (!skipNativeCall) {
      if (message.method && message.method.indexOf('vsta:') === 0) {
        target = 1;
      }
      // eslint-disable-next-line no-param-reassign, new-cap
      message = new window.Module.ppVar(message);

      // The second parameter is 'target' -- it allows us the backend to
      // decide how to best handle the message without having to parse them
      // -- i.e., some messages may need to be directed to different
      // workers than the default DataCollection worker.
      window.Module.vstBackendSendMsg(message, target);

      // pp::Var objects created from the JS end must be explicitely deleted
      message.delete();
    }
  }
}

const checkHostStateRequest = msg => {
  return msg.result?.IS_HOST_SERVICE_STATE;
};

const checkIsNotification = msg => {
  return !msg.id;
};

/**
 * the client RPC instance
 */
class RPCServiceClient extends RPCBase {
  blockResponses = true;

  addPending = false;

  pending = [];

  /** @override */
  checkResponseMessage(msg) {
    // allow response to be processed
    if (this.blockResponses && !checkIsNotification(msg)) {
      this.onmessage(msg);
    } else if (this.addPending) {
      this.pending.push(msg);
    }

    // do a check for the response querying host state
    if (this.blockResponses && checkHostStateRequest(msg)) {
      // start capturing pending notifications
      this.addPending = true;
      this.onmessage(msg);
    }
  }

  /** @override */
  postMessage(message) {
    // check for when client is finished processing
    // host state
    if (message.method === 'CLIENT_STATE_RESTORED') {
      this.addPending = false;
      this.blockResponses = false;

      // flush any pending notifications that occurred between the time
      // when the host state was queried and when the client applied the
      // state
      this.pending.forEach(msg => {
        this.onmessage(msg);
      });
      this.pending = [];
    } else {
      super.postMessage(message);
    }
  }
}

export { RPCService, RPCServiceClient };
