/* eslint-disable no-prototype-builtins */
import EventEmitter from 'eventemitter3';
import { clone } from 'lodash-es';

import { http } from '@utils/http.js';
import { eventHandlers } from '@services/datacollection/DataCollection.handlers.js';
import { UsbDeviceDiscovery as DeviceDiscovery } from '@services/datacollection/UsbDeviceDiscovery-chrome.js';
import { getFeatureFlags } from '@services/featureflags/featureFlags.js';
import { getText } from '@utils/i18n.js';
import { makeObservable, observable, action } from 'mobx';
import { vstAuthStore } from '@stores/vst-auth.store.js';

const verboseLogging = false;

// this is the amount of time to wait before forcing uninit to return
// we do this to make sure that if uninit hangs for some reason, we'll still
// resolve the uninit promise
// this is only a failsafe, so make the timeout long enough
const UNINIT_TIMEOUT_MS = 5000;

const FIRMWARE_MANIFEST_FILE = 'resources/firmware.json';

export class DataCollection extends EventEmitter {
  constructor(config) {
    super();
    this.nativeModulesAPI = config.nativeModulesAPI; // TODO: move this initialization to it's own service
    this.api = config.api;
    this.usbDiscovery = null;
    this.sensorMap = config.sensorMap;
    this.deviceServerPorts = config.deviceServerPorts;
    this.appManifest = config.appManifest;

    this._timeUnits = 's';

    Object.keys(eventHandlers).forEach(key => this.api.on(key, eventHandlers[key].bind(this)));

    this.mode = 'time-based';
    this.isTimeBased = true;
    this.spectrumMode = ''; // set when opening a file or starting a new session

    this.defaultTimeParams = {}; // set during init and new data collection session

    this.defaultEventParams = {
      delta: 0.25, // set during init and new data collection session
      name: getText('Event'),
      units: '',
      accumulate: 0,
      average: false,
      isDirty: false,
    };

    this.defaultDropCountingParams = {
      delta: 0.1, // set during init and new data collection session
    };

    this.defaultFullSpectrumParams = {};
    this.defaultPhotogateTimingParams = {
      subMode: 'motion',
      numEvents: 8,
      params: {
        units: 'm',
        barWidth: 0.05,
      },
    };

    this.eventBasedParams = {};
    this.paramsLookup = {};
    this.timeBasedParams = {};

    this.dataMarksEnabled = false;

    this._pollDevicesTimerId = null;

    makeObservable(this, {
      dataMarksEnabled: observable,
      setDataMarksEnabled: action,
      setDefaultParams: action,
    });
  }

  get timeUnits() {
    return this._timeUnits;
  }

  set timeUnits(units) {
    this._timeUnits = units;
    if (this.timeBasedParams) {
      this.timeBasedParams.units = units;
      this.emit('time-units-changed', units);
    }
  }

  // FIXME: HACK to work around circular dependency:
  // SensorWorld depends on DataCollection, yet DataCollection depends on SensorWorld
  setSensorWorld(sensorWorld) {
    this.sensorWorld = sensorWorld;
  }

  setDefaultParams(imported) {
    if (!imported) {
      this.timeBasedParams = {};
      this.timeUnits = 's';
      this.timeBasedParams = { ...this.defaultTimeParams };
      this.timeBasedParams.units = this.timeUnits;
      this.eventBasedParams = { ...this.defaultEventParams };
      this.dataMarksEnabled = false;
    }

    // NOTE: this must be ordered in this way to be updated correctly
    this.paramsLookup = {
      'time-based': this.timeBasedParams,
      'event-based': this.eventBasedParams,
      'events-with-entry': this.eventBasedParams,
      'selected-events': this.eventBasedParams,
      'drop-counting': this.defaultDropCountingParams,
      'photogate-timing': this.defaultPhotogateTimingParams,
      'full-spectrum': this.defaultFullSpectrumParams,
    };
  }

  cancelKeep() {
    return this.api.cancelKeep(this.experimentId);
  }

  init(config = {}) {
    console.assert(config.sensorMap);

    if (this.deviceServerPorts) {
      config.deviceServerPorts = this.deviceServerPorts;
    }

    if (!config.hasOwnProperty('hasFirmwareUpdater')) {
      config.hasFirmwareUpdater = true;
    }

    if (!config.featureFlags) {
      config.featureFlags = getFeatureFlags();
    }

    const appInfo = {};
    const { appManifest } = this;

    appInfo.platform = window.navigator.platform;
    appInfo.version = appManifest.getAppVersion();
    appInfo.shortName = appManifest.getAppShortName();

    config.appInfo = appInfo;

    const startupUsbDiscovery = () => {
      if (!this.usbDiscovery && DeviceDiscovery) {
        this.usbDiscovery = new DeviceDiscovery();
        this.usbDiscovery.on('device-added', this.usbDeviceAttached.bind(this));
        this.usbDiscovery.on('device-removed', deviceDesc => {
          this.usbDeviceDetached(deviceDesc).then(() => {
            this.emit('device-detached');
          });
        });
        this.usbDiscovery.start();
      }
    };

    return new Promise((resolve, reject) => {
      const doInit = firmwareInfo => {
        config.firmwareInfo = firmwareInfo;
        this.nativeModulesAPI
          .init(config)
          .then(result => {
            if (PLATFORM_ID !== 'web') {
              startupUsbDiscovery();

              const poll = () => {
                // Experiment id can be momentarily in flux between sessions.
                // Guard on experimentId to prevent spurious error messages
                // in the console.
                if (this.experimentId) this.api.pollDevices(this.experimentId);
                this._pollDevicesTimerId = setTimeout(poll, 2000);
              };
              this._pollDevicesTimerId = setTimeout(poll, 0);
            }

            if (result.defaults) {
              const { defaults } = result;
              this.defaultTimeParams = defaults.defaultTimeBasedParams;
              this.defaultEventParams.delta = defaults.defaultEventsModeParams.delta;
              this.setDefaultParams();
            }

            // init returns a collection of enumerations
            // DeviceError object added to DataCollection prototype
            if (typeof result.enums === 'object') {
              Object.keys(result.enums).forEach(name => {
                DataCollection[name] = result.enums[name];
                this[name] = result.enums[name];
              });
            }

            resolve(result);
          })
          .catch(reject);
      };

      // get the firmware manifest file, if it exists
      if (config.hasFirmwareUpdater) {
        http
          .getJSON(FIRMWARE_MANIFEST_FILE)
          .then(doInit)
          .catch(() => doInit({}));
      } else {
        doInit();
      }
    });
  }

  uninit(params) {
    return new Promise((_resolve, reject) => {
      let resolved = false;
      const timeout = null;
      const resolve = (...args) => {
        clearTimeout(timeout);
        if (!resolved) {
          _resolve(...args);
        } else {
          resolved = true;
        }
      };

      // stop polling, if we are
      clearTimeout(this._pollDevicesTimerId);
      this._pollDevicesTimerId = null;

      if (this.usbDiscovery) {
        this.usbDiscovery.stop();
      }

      // in the event that the uninit hangs, we'll use a very long timeout
      // to ensure that the promise does eventually return
      const timeoutId = setTimeout(() => {
        console.warn('Native module uninit failed to complete, forcing.');
        resolve();
      }, UNINIT_TIMEOUT_MS);

      this.nativeModulesAPI.uninit(params).then(() => {
        clearTimeout(timeoutId);
        resolve();
      }, reject);
    });
  }

  createExperiment() {
    return this.api.createExperiment();
  }

  // TODO: (@ejdeposit) Additional parameters exist for dataCollection and manualCollection sessionTypes
  /**
   * @param {object} [params={}]
   * @param {number} params.experimentId - UDM id of the experiment
   * @param {string} params.sessionType - type of collection
   * @param {string} params.sourceName - source for name local data share
   * @param {string} params.sourceURI - source URI for local data share
   * @param {boolean} params.empty
   * @returns {Promise}
   */
  sessionStarting(params = {}) {
    this.experimentId = params.experimentId;

    this.setDefaultParams();

    // since we are expecting the first dataset to be created for us,
    // we need to indicate what it should be called
    const sessionParams =
      params.sessionType === 'DataShare'
        ? { ...params }
        : { experimentId: this.experimentId, empty: params.imported || false };

    if (params.initialDataSetName) {
      sessionParams.datasetName = params.initialDataSetName;
    }

    if (params.sessionType) {
      sessionParams.sessionType = params.sessionType;
    }

    if (params.type) {
      sessionParams.collectionMode = params.type;
    }

    if (params.spectrumMode) {
      sessionParams.spectrumMode = params.spectrumMode;
      this._updateSpectrumMode(params.spectrumMode);
    }

    if (params.sessionSubtype) {
      sessionParams.sessionSubtype = params.sessionSubtype;
    }

    return this.api.sessionStarting(sessionParams);
  }

  sessionClosing() {
    this.sensorWorld.resetSensorState();
    const expId = this.experimentId;
    this.experimentId = null;
    return this.api.sessionClosing(expId);
  }

  sessionRestoring(params) {
    this.experimentId = params.experimentId;
    return this.api.sessionRestoring(params);
  }

  // SENSORWORLD
  usbDeviceAttached(deviceDesc) {
    if (verboseLogging) {
      console.log('usbDeviceAttached');
      console.dir(deviceDesc);
    }
    return this.api.usbDeviceAttached({ experimentId: this.experimentId, ...deviceDesc });
  }

  // SENSORWORLD
  usbDeviceDetached(deviceDesc) {
    if (verboseLogging) {
      console.log('usbDeviceDetached');
      console.dir(deviceDesc);
    }

    return this.api.usbDeviceDetached({ experimentId: this.experimentId, ...deviceDesc });
  }

  setSpectrumDataMode(mode) {
    return new Promise(resolve => {
      this.api.setSpectrumDataMode({ experimentId: this.experimentId, mode }).then(result => {
        if (verboseLogging) {
          console.log('Set Spectrum Data Mode:');
          console.dir(result);
        }
        this._updateSpectrumMode(mode);
        resolve(result);
      });
    });
  }

  _updateSpectrumMode(mode) {
    this.spectrumMode = mode;
    this.emit('spectrum-mode-changed', mode);
  }

  _setCollectionParams(newParams) {
    if (!newParams) {
      return;
    }

    const oldSettings = {
      mode: this.mode,
      params: clone(this.paramsLookup[this.mode]),
    };

    const { mode, importing, params = {} } = newParams;
    const newMode = mode;
    let delta;
    let duration;

    if (this.mode !== newMode) {
      this.collectionModeChanged(newMode, importing);
    }

    this.isTimeBased = true;

    if (params.hasOwnProperty('delta')) {
      delta = params.delta;
    }

    if (params.hasOwnProperty('duration')) {
      duration = params.duration;
    }

    if (newMode === 'photogate-timing') {
      this.paramsLookup[newMode] = params;
    }

    // Ignore this flag unless we're importing, otherwise it turns data marks off when they should be on.
    if (params.hasOwnProperty('dataMarksEnabled') && importing && vstAuthStore.authorized)
      this.setDataMarksEnabled(params.dataMarksEnabled, importing);

    const dcParams = this.paramsLookup[newMode];

    switch (newMode) {
      case 'selected-events':
      case 'events-with-entry':
      case 'drop-counting':
      case 'photogate-timing':
        this.isTimeBased = false;
        break;

      default:
        if (
          typeof delta !== 'undefined' &&
          params.hasOwnProperty('duration') &&
          params.hasOwnProperty('continuous')
        ) {
          dcParams.delta = delta;
          dcParams.duration = duration;
          dcParams.continuous = params.continuous;
          dcParams.remoteLogging = params.remoteLogging;
          dcParams.triggering = params.triggering;
          dcParams.units = params.units;
          if (newMode === 'time-based') {
            this.timeUnits = params.units;
          }

          if (params.hasOwnProperty('triggering')) {
            Object.keys(params.triggering).forEach(key => {
              this.timeBasedParams.triggering[key] = params.triggering[key];
            });
          }
        }
        break;
    }

    const newSettings = {
      mode: newMode,
      params: this.paramsLookup[newMode],
    };

    this.notifyCollectionParamsChanged(newSettings, oldSettings);

    if (verboseLogging) {
      console.log('Collection settings changed:');
      console.dir(newParams);
    }
  }

  /**
   * Set collection params
   *
   * @param {string} mode collection mode
   * @param {object} params collection params
   * @param {boolean} importing
   * @return {Promise} a promise that resolves when the API request completes
   */
  async setCollectionParams(mode, params = {}, importing) {
    // In manual mode this is NOP
    if (mode === 'manual') {
      return;
    }

    if (!params.hasOwnProperty('delta')) {
      params.delta = this.paramsLookup[mode].delta;
    }

    if (mode === 'time-based') {
      if (params.hasOwnProperty('units') && this.timeUnits !== params.units) {
        // Set timeUnits here since we always use seconds and convert on the front end
        this.timeUnits = params.units;
      } else {
        params.units = this.timeUnits;
      }
    } else if (mode.match('events')) {
      this.eventBasedParams.accumulate = params.accumulate;
      this.eventBasedParams.average = params.average || false;

      if (Number.isNaN(params.accumulate)) {
        // Failsafe if accumulate is NaN
        this.eventBasedParams.accumulate = 0;
      }
    }

    if (importing) {
      return this._setCollectionParams({
        mode,
        params,
        importing: true,
      });
    } else {
      return await this.api.setCollectionParams({
        experimentId: this.experimentId,
        mode,
        params,
      });
    }
  }

  getCollectionParams(collectMode) {
    collectMode = collectMode || this.mode;
    return {
      mode: collectMode,
      params: this.paramsLookup[collectMode],
    };
  }

  notifyCollectionParamsChanged(newSettings, oldSettings) {
    if (newSettings.params && newSettings.params.continuous && newSettings.params.delta < 0.005) {
      // hack to force
      const params = { ...newSettings.params };
      params.continuous = false;
      this.setCollectionParams(this.mode, params).then(() => {
        if (verboseLogging) {
          console.warn('reset collection continuous to false due to data rate');
        }
      });
    } else {
      this.emit('collection-params-changed', newSettings, oldSettings);
    }
  }

  collectionModeChanged(newMode, importing) {
    const prevMode = this.mode;
    this.mode = newMode;

    this.emit('collection-mode-changed', {
      to: newMode,
      from: prevMode,
      importing,
    }); // pass transition object
  }

  prepareRemoteLogging(params) {
    const { deviceId, config } = params;

    return this.api.prepareRemoteLogging(this.experimentId, deviceId, config).then(() => {
      console.log('successfully prepared remote logging');
    });
  }

  retrieveLoggedData(params) {
    const { deviceId, deleteDataAfterRetrieval } = params;
    return this.api.retrieveLoggedData(this.experimentId, deviceId, deleteDataAfterRetrieval);
  }

  deleteLoggedData() {
    return this.api.deleteLoggedData(this.experimentId);
  }

  /**
   * Detach all currently logging remote devices
   */
  detachRemoteDevices() {
    return this.api.detachRemoteDevices(this.experimentId);
  }

  setMeasurementPeriod(/* deltaSeconds */) {
    return Promise.reject(new Error('DataCollection:setMeasurementPeriod is no longer used'));
  }

  setCollectionMode(mode) {
    return this.api.setCollectionMode(this.experimentId, mode);
  }

  // duration can be null for continuous collection
  startMeasurements(duration) {
    return new Promise((resolve, _reject) => {
      const reject = err => {
        console.error(err);
        this.emit('start-measurements-failed');
        _reject();
      };

      this.api
        .startMeasurements(this.experimentId, duration)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  stopMeasurements(params = {}) {
    params.experimentId = this.experimentId;
    return this.api.stopMeasurements(params).then(() => {
      if (verboseLogging) {
        console.log('Stop measurements');
      }
    });
  }

  // is this called from anywhere?
  setCanConfigure(canConfig) {
    return this.api.setCanConfigure(this.experimentId, canConfig);
  }

  // force is optional
  searchForSensors(force) {
    return new Promise((resolve, reject) => {
      this.api.searchForSensors(this.experimentId, force).then(() => {
        // TODO: look into a better way to handle this in the future.
        // This is a kluge but should give the sensors enough time to settle.
        setTimeout(() => {
          resolve();
        }, 1000);
      }, reject);
    });
  }

  keepData() {
    const { sensorWorld } = this;
    const params = this.eventBasedParams;
    const hasRadiationMonitor = sensorWorld.sensors.find(sensor =>
      sensorWorld.isRadiationMonitor(sensor.id),
    );

    if (!hasRadiationMonitor && params.accumulate !== 0 && !params.average) {
      this.eventBasedParams.accumulate = 0;
    }

    return this.api.keepData(this.experimentId, Date.now(), params.accumulate, params.average);
  }

  enableLiveVoltage(enable) {
    return this.api.enableLiveVoltage(this.experimentId, enable);
  }

  /**
   * Toggle data marks on or off
   * @param {boolean?} newValue (optional) instead of toggling, specify an explicit value.
   */
  setDataMarksEnabled(newValue = null, importing = false) {
    if (newValue === null) newValue = !this.dataMarksEnabled;
    this.dataMarksEnabled = newValue;

    if (!importing) {
      this.api.setDataMarksEnabled(this.experimentId, newValue);
    }
  }
}
