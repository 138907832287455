import { nextTick } from '@utils/nextTick.js';

export const exportDataCollection = dataCollection => {
  const out = {};

  out.timeUnits = dataCollection.timeUnits;
  out.mode = dataCollection.mode;
  out.isTimeBased = dataCollection.isTimeBased;
  out.spectrumMode = dataCollection.spectrumMode;
  out.timeBasedParams = dataCollection.timeBasedParams;
  out.defaultTimeParams = dataCollection.defaultTimeParams;
  out.defaultEventParams = dataCollection.defaultEventParams;
  out.defaultDropCountingParams = dataCollection.defaultDropCountingParams;
  out.defaultFullSpectrumParams = dataCollection.defaultFullSpectrumParams;
  out.defaultPhotogateTimingParams = dataCollection.defaultPhotogateTimingParams;
  out.eventBasedParams = dataCollection.eventBasedParams;

  return out;
};

export const importDataCollection = async (dataCollection, state) => {
  dataCollection.appInitialized = new Promise(resolve => {
    dataCollection.on('app-initialized', resolve);
  });
  dataCollection.mode = state.mode;
  dataCollection.isTimeBased = state.isTimeBased;
  dataCollection.spectrumMode = state.spectrumMode;
  Object.assign(dataCollection.timeBasedParams, state.timeBasedParams);
  Object.assign(dataCollection.defaultTimeParams, state.defaultTimeParams);
  Object.assign(dataCollection.defaultEventParams, state.defaultEventParams);
  Object.assign(dataCollection.defaultDropCountingParams, state.defaultDropCountingParams);
  Object.assign(dataCollection.defaultFullSpectrumParams, state.defaultFullSpectrumParams);
  Object.assign(dataCollection.defaultPhotogateTimingParams, state.defaultPhotogateTimingParams);
  Object.assign(dataCollection.eventBasedParams, state.eventBasedParams);

  dataCollection.timeUnits = state.timeUnits;
  dataCollection.setDefaultParams(true);

  dataCollection.appInitialized.then(() => {
    dataCollection._setCollectionParams({
      mode: dataCollection.mode,
      importing: true,
      params: dataCollection.paramsLookup[dataCollection.mode],
    });

    dataCollection.collectionModeChanged(dataCollection.mode, true);
  });

  await nextTick(10); // allows events to be processed
};
