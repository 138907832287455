import { clone, difference } from 'lodash-es';
import { initDataShareAutoLayout } from '@services/datashare/dataShareAutoLayout.js';
import { DataShareClient } from '@services/datashare/DataShareClient.js';
import { EventBinder } from '@utils/EventBinder.js';
import { http } from '@utils/http.js'; // TODO: move to service

const COLUMN_KEYS = [
  'color',
  'formatStr',
  'liveValue',
  'liveValueTimeStamp',
  'name',
  'position',
  'units',
  'symbol',
];

const GROUP_KEYS = ['name', 'units', 'formatStr'];

function parseFormatStringAsPrecision(formatString) {
  // this is a bit of a hack to extract the precision number out of the
  // format string in order to setup a correct precision object for the
  // backend to return back to us if we do not send a valid precision
  // object the backend will always send back precision of 0
  let precision = parseFloat(formatString.replace(/^%0?./, ''));
  if (Number.isNaN(precision)) precision = 1;

  return {
    precision,
    automatic: false,
    useSciNotation: false,
    useSigFigs: false,
  };
}

// bind changes on a DataShare Column with a DataWorld column
function bindColumn(dataWorld, dsColumn, column) {
  function performUpdate(key, value) {
    // If the change is one of group properties we notify the backend,
    // and the change then propagates into the FE. For the values,
    // we modify the FE object directly, and this then automatically
    // propagates into the backend.
    // (At some point we should look into whether the column values
    // need to be handled specially in the FE.)
    if (GROUP_KEYS.includes(key)) {
      const props = {};
      if (key === 'formatStr') props.precision = parseFormatStringAsPrecision(value);
      else props[key] = value;

      dataWorld.updateColumnGroup(column.groupId, props);
    } else column[key] = dsColumn[key];
  }

  // bind to changes on the property and set the initial values
  function bindColumnProps(key) {
    performUpdate(key, dsColumn[key]);
    dsColumn.on(`${key}-changed`, value => performUpdate(key, value));
  }

  COLUMN_KEYS.forEach(key => bindColumnProps(key));
  // Do not bind groupId or setId, it's been remapped in the column to the
  // correct UDM value

  dsColumn.on('values-changed', values => {
    const rowIndexes = values.map((_, i) => i);
    column.values = values;
    // Important: we always call updatedRows because this forces
    // dataWorld to tell native modules to update the values
    column.updatedRows = rowIndexes;
  });

  dsColumn.on('dataset-name-changed', name => {
    column.emit('dataset-name-changed', name);
  });
}

// bind changes on a DataShare Column with a DataWorld column
function bindDataSet(dataWorld, dsDataSet, dataSet) {
  // bind to changes on the property and
  // set the initial
  function bindDataSetProps(key) {
    dataSet[key] = dsDataSet[key];
    dsDataSet.on(`${key}-changed`, value => {
      const props = {};
      props[key] = value;
      dataWorld.updateDataSet(dataSet.id, props);
    });
  }

  bindDataSetProps('position');
  bindDataSetProps('name');

  // special handling for column IDs
  function updateColumnIDs(columnIds) {
    const foreignIds = dataSet.columnIds.map(id => {
      const column = dataWorld.getColumnById(id);
      if (column && column.foreignId) {
        return `${column.foreignId}`;
      }

      return undefined;
    });

    const removed = difference(foreignIds, columnIds);

    removed.forEach(colId => {
      dataWorld.removeInternalColumn(colId);
    });
  }
  updateColumnIDs(dsDataSet.columnIds);

  dsDataSet.on('column-ids-changed', columnIds => {
    updateColumnIDs(columnIds);
  });
}

export class DataShareSession {
  constructor({ dataWorld, udm }) {
    this.client = null; // DataShareClient
    this.dataWorld = dataWorld;
    this.dataCollection = dataWorld.dataCollection;
    this.udm = udm;

    this.clientEventBinder = null;
    this.dwEventBinder = null;

    this.serverSessionID = null;
    this.collection = {};
  }

  // opts.sourceURI = 'http://mylq.local'
  async start(params = {}) {
    console.assert(!this.client);
    const { dataCollection, dataWorld } = this;

    this.client = new DataShareClient(http, params);
    this.dwEventBinder = new EventBinder();

    const { client } = this;
    this.dwEventBinder.on(dataWorld, 'dataset-added', dataSet => {
      const foreignId = `${dataSet.foreignId}`;
      const dataSets = client.getDataSets();

      let dsDataSet;

      dataSets.forEach(ds => {
        if (ds.id === foreignId) {
          dsDataSet = ds;
          // Store the native UDM id, so we can map this dataset for
          // UDM calls
          dsDataSet.nativeId = dataSet.id;
        }
      });

      if (dsDataSet) {
        bindDataSet(dataWorld, dsDataSet, dataSet);
      } else {
        console.error('Failed to locate dataset');
      }
    });

    this.dwEventBinder.on(dataWorld, 'column-added', column => {
      const foreignId = `${column.foreignId}`;
      const dsColumn = client.getColumns().find(column => column.id === foreignId);

      if (dsColumn) {
        dsColumn.nativeId = column.id;
        dsColumn.nativeGroupId = column.groupId;

        bindColumn(dataWorld, dsColumn, column);
        this.client.emitUdmColumnAdded(dsColumn);

        // initialize dataworld column with datashare column values
        setTimeout(() => {
          const dsColumnValues = dsColumn.values || [];
          const rowIndexes = dsColumnValues.map((_, i) => i);
          column.values = [...dsColumnValues];
          column.updatedRows = [...rowIndexes]; // NOTE: this needs to be updated after values to notify vst-native-modules
        });
      } else {
        console.warn('Failed to locate column');
      }
    });

    this.clientEventBinder = this._bindClientEvents();

    dataWorld.emit('collection-keep-changed', false);

    this.sourceName = params.sourceName;
    this.sourceURI = params.sourceURI;

    if (params.imported) {
      dataWorld.emit('collection-control-changed', false); // TODO: move to DataWorld
      this.client.sourceName = this.sourceName;

      try {
        await dataCollection.sessionRestoring(params);
        this.restoredSession = true;
      } catch (e) {
        throw new Error(e);
      }
    } else {
      const sessionParams = {
        empty: true,
        sessionType: 'DataShare',
        sourceURI: params.sourceURI,
        sourceName: params.sourceName,
        experimentId: params.experimentId,
      };

      try {
        /*
        TODO: (@ejdeposit) May make more sense to refactor so that sessionStarting
        does just one thing, and just set experimentId on dataCollection  and call
        the corresponding API directly
        */
        await dataCollection.sessionStarting(sessionParams);
        await this.client.start(this.sourceURI, params.imported);
      } catch (error) {
        console.error(error);
      }
    }
  }

  stop() {
    console.assert(this.client);
    const { dataCollection } = this;

    this.dwEventBinder.unbindAll();
    dataCollection.sessionClosing();

    return new Promise((_resolve, _reject) => {
      const resolve = () => {
        this.client = null;
        _resolve.apply(this, arguments); // eslint-disable-line
      };

      const reject = () => {
        _reject.apply(this, arguments); // eslint-disable-line
        this.client = null;
      };

      this.clientEventBinder.unbindAll();
      this.clientEventBinder = null;
      this.client.stop().then(resolve, reject);
    });
  }

  startCollection() {
    return new Promise((resolve, reject) => {
      if (this.client) {
        this.client
          .requestStartCollection()
          .then(() => {
            resolve();
          })
          .catch(error => {
            console.error('Failed to start collection on DataShareClient');
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  stopCollection() {
    return new Promise((resolve, reject) => {
      if (this.client) {
        this.client.requestStopCollection().then(
          () => {
            resolve();
          },
          error => {
            console.error('Failed to stop collection on DataShareClient');
            reject(error);
          },
        );
      } else {
        reject();
      }
    });
  }

  // This function binds to all changes on the DataShareClient
  // and mirrors those values to the DataWorld's DataSets, Columns and Meters
  _bindClientEvents() {
    const { client, dataWorld } = this;

    const eventBinder = initDataShareAutoLayout(dataWorld, client);

    const DATASHARE_EVENT_HANDLERS = {
      // Client's Connection State
      connect: function connect() {},

      restored: function restored() {
        dataWorld.emit('session-source-name-changed', dataWorld.sessionSourceName); // TODO: move to DataWorld
      },

      error: function error(error) {
        dataWorld.emit('session-connection-error', error); // TODO: move to DataWorld
      },

      // session { id: 'session-id', desc: 'description' }
      'server-session-changed': function serverSessionchanged(serverSession) {
        if (this.serverSessionID === null) {
          this.serverSessionID = serverSession.id;
        } else if (this.serverSessionID !== serverSession.id) {
          // the server's session ID has changed, so we'll disconnect permanently

          eventBinder.unbindAll(); // stop all bindings to client
          dataWorld.emit('session-remote-id-changed');
          this.client.stop().catch(error => {
            console.error('Failed to stop client: ', error);
          });
        }
      },

      'server-collection-changed': function serverCollectionChanged(collection) {
        const controlChanged = this.collection.canControl !== collection.canControl;
        const collectingChanged = this.collection.isCollecting !== collection.isCollecting;
        this.collection = clone(collection);

        if (controlChanged) {
          dataWorld.emit('collection-control-changed', collection.canControl); // TODO: move to DataWorld
        }
        if (collectingChanged) {
          if (collection.isCollecting) {
            dataWorld.notifyCollectionPreparing();
            dataWorld._notifyCollectionStarted(); // TODO: move to DataWorld
          } else {
            dataWorld._notifyCollectionStopped(); // TODO: move to DataWorld
          }
          dataWorld.isCollecting = this.collection.isCollecting;
        }
      },

      // DataSets
      'dataset-added': function dataSetAdded(dsDataSet) {
        // we cannot create an internal data set in the dw -- we must
        // request a normal dataset, and defer the object binding to the
        // DW dataset-added handler. To this end we pass the internal
        // object id so that we can use it in the handler to match the
        // UDM id to our internal id
        // const dataSet = dw.createInternalDataSet(dsDataSet.id);
        dataWorld.createNewDataSet({
          foreignId: dsDataSet.id,
          name: dsDataSet.name,
        });
      },

      'dataset-removed': function dataSetRemoved(dsDataSet) {
        const nativeId = dsDataSet.nativeId || dsDataSet.id;
        dataWorld.removeInternalDataSet(nativeId);
      },

      // Columns
      'column-added': function columnAdded(dsColumn) {
        const params = {};

        Object.keys(dsColumn).forEach(key => {
          if (COLUMN_KEYS.includes(key)) {
            params[key] = dsColumn[key];
          }
        });

        params.foreignId = dsColumn.id;
        params.foreignGroupId = parseInt(dsColumn.groupId);
        params.foreignSetId = parseInt(dsColumn.setId);
        params.editable = false;
        params.deletable = false;
        params.name = dsColumn.name;

        dataWorld.createNewColumn(params);
      },

      'column-removed': function columnRemoved(dsColumn) {
        const nativeId = dsColumn.nativeId || dsColumn.id;
        // Pass in true as 2nd parameter to also remove the column group.
        // (MEG-2106)
        dataWorld.removeInternalColumn(nativeId, true);
      },
    };
    // bind to the DataShareClient
    Object.keys(DATASHARE_EVENT_HANDLERS).forEach(eventName => {
      const handler = DATASHARE_EVENT_HANDLERS[eventName];
      eventBinder.bind(client, eventName, handler.bind(this));
    });

    return eventBinder;
  }
}
