import { makeObservable, action, runInAction } from 'mobx';
import { EventBinder } from '@utils/EventBinder.js';
import { Meter } from '@common/mobx-stores/Meter.js';
import { MeterPositionCompare } from '@common/utils/meterListHelpers.js';
import { serializeFunction } from '@utils/serializeFunction.js';

/**
 * @typedef {object} OverrideResult result object returned by an
 * OverrideFunction
 * @property {Meter.VisibilityOverride} [meter] optional visibility override affects
 * meters shown in the meters pane.
 * @property {Meter.VisibilityOverride} [bar] optional visibility override affects
 * meters shown in the bottom bar.
 */

/**
 * A function you can pass into `MeterCentral.addOverrideFunction()`
 * to override the default visibility behavior.
 * @callback OverrideFunction
 * @param {Column} column for which a newly minted meter will be created.
 * @returns {OverrideResult} will specify whether a meter will be displayed or
 * not for either bottom bar or meter pane.
 */

/**
 * @classdesc singleton that manages the creation of meters and matching them to
 * columns, sensors, etc.
 */
export class MeterCentral {
  /**
   * MeterCentral must be constructed with a dataWorld and sensorWorld instance.
   * @param {object} param
   * @param {DataWorld} param.dataWorld
   * @param {SensorWorld} param.sensorWorld
   */
  constructor({ dataWorld, sensorWorld }) {
    console.assert(dataWorld, 'DataWorld must be passed into MeterCentral constructor');
    console.assert(sensorWorld, 'SensorWorld must be passed into MeterCentral constructor');
    this.dataWorld = dataWorld;
    this.sensorWorld = sensorWorld;
    this._eventBinder = new EventBinder();

    this._handleColumnAdded = serializeFunction(this.__handleColumnAdded.bind(this));

    this._eventBinder.bindListeners({
      source: dataWorld,
      target: this,
      eventMap: {
        'column-added': '_handleColumnAdded',
        'column-removed': '_handleColumnRemoved',
        'session-started': '_handleSessionStarted',
        'session-closing': '_handleSessionClosing',
        'device-sensor-enum-completed': '_applyVisibilityOverrides',
      },
    });

    this._overrides = [];

    makeObservable(this, {
      updateMeterProperties: action,
    });
  }

  /**
   * Adds a visibility override allowing applications to extend the visibility
   * requirements for newly created meters.
   * @param {OverrideFunction} overrideFunc
   */
  addOverrideFunction(overrideFunc) {
    this._overrides.push(overrideFunc);
  }

  /**
   * Clears out all visibility overrides.
   */
  clearOverrideFunctions() {
    this._overrides = [];
  }

  /**
   * Handler for session start up. If this session is initiated by file->open
   * then this will re-constitute meters from the saved meter info contained in
   * udm.
   * @param {object} params
   * @param {boolean} params.imported True if the session was started by opening
   * a file, false if it was file->new et al.
   * @private clients should not call this method directly.
   */
  async _handleSessionStarted({ imported = false }) {
    // If session is imported from a file, we'll rehydrate our meters here.
    if (imported) {
      const { dataWorld } = this;
      // Restore meters from udm.
      const meterInfos = await dataWorld.getStoredMeterInfo(dataWorld.experimentId);
      meterInfos.sort(MeterPositionCompare);
      // If any of the meters map to non-existent columns, then we'll assume
      // this is a legacy file from waaaay back when meters weren't ever
      // correctly mapped to anything in the front end.
      if (
        meterInfos.length === 0 ||
        meterInfos.some(({ columnId }) => {
          return !dataWorld.getColumnById(columnId);
        })
      ) {
        console.warn(`Stored meters missing or incorrect. Building from scratch.`);
        dataWorld.removeAllMeters();
        const initAll = dataWorld.currentDataSet.columnIds.map(colId =>
          this._addMeterForColumn(dataWorld.getColumnById(colId)),
        );
        await Promise.all(initAll);
        return;
      }

      meterInfos.forEach(async meterInfo => {
        const newMeter = new Meter(meterInfo);
        // Pass in true because we don't need to round-trip this back through udm.
        await dataWorld.addMeter(newMeter, true);
        this._addBindings(newMeter);
        this.updateMeterProperties(newMeter);
      });
    }
    this._applyVisibilityOverrides();
  }

  /**
   * Called when session is closing and cleaning up. Removes all meters from the
   * DataWorld.
   */
  _handleSessionClosing() {
    const { dataWorld } = this;
    dataWorld.meters.forEach(meter => dataWorld.removeMeter(meter.id));
  }

  /**
   * Handler for DataWorld `column-added` events. This will create and configure
   * a corresponding meter for the new column.
   * @param {Column} column newly created column.
   * @private clients should not call this method directly.
   */
  async __handleColumnAdded({ id: fakeId }) {
    const { dataWorld } = this;
    // During file open churn, esp. when there are sensors connected,
    // `column-added` will pass in an object which doesn't represent the final
    // columnn; properties are often missing. To fix this, we can fetch the real
    // column directly from dataworld.
    const column = dataWorld.getColumnById(fakeId);
    if (!column || column.special) return;

    const { meterId, id } = column;
    const { meters } = dataWorld;

    // We ignore column addition during file->open or session closing.
    if (dataWorld.importing || dataWorld.sessionClosing) {
      return;
    }

    // If the column has been assigned a meterId, then we'll look for the meter
    // and make it update.
    const existingMeter = dataWorld.getMeterById(meterId);
    if (existingMeter) {
      // Assign the new columnId to the meter, then update its properties and
      // reset its bindings.
      runInAction(() => {
        existingMeter.columnId = column.id;
      });

      this.updateMeterProperties(existingMeter);
      runInAction(() => existingMeter.removeAllBindings());
      this._addBindings(existingMeter);
      return;
    }

    const errantMeter = meters.find(meter => meter.columnId === id);
    console.assert(
      !errantMeter,
      `A meter id ${
        errantMeter?.id ?? 'n/a'
      } already exists for column id ${id}, but it hasn't been correctly linked to the group.`,
    );

    await this._addMeterForColumn(column);
    this._pruneOrphans();
  }

  _handleColumnRemoved(column) {
    if (column.special) return;

    const { dataWorld } = this;
    const meter = dataWorld.getMeterById(column.meterId);

    if (meter) dataWorld.removeMeter(meter.id);
    this._pruneOrphans();
  }

  /**
   * Will remove 'orphaned' meters -- meters which do not have associated
   * columns and or column groups.
   * @private
   */
  _pruneOrphans() {
    const { dataWorld } = this;
    const { meters } = dataWorld;

    // Two step deletion to prevent `dataWorld.meters` list from getting mutated
    // during iteration. First build an array of orphaned meter ids.
    const meterIdsToDelete = [];
    meters.forEach(meter => {
      const { columnId, groupId } = meter;
      const columnExists = !!dataWorld.getColumnById(columnId);
      const groupExists = !!dataWorld.getColumnGroupById(groupId);
      if (!columnExists || !groupExists) {
        meterIdsToDelete.push(meter.id);
      }
    });

    // Next delete the meters one by one.
    meterIdsToDelete.forEach(meterId => {
      dataWorld.removeMeter(meterId);
    });
  }

  /**
   * Logic that creates a meter based on a given column.
   * @param {Column} column
   * @private
   */
  async _addMeterForColumn(column) {
    const { sensorWorld, dataWorld } = this;
    const { id, name, color, metered, group, units, sensorId } = column;
    console.assert(group, `Column ${id} missing group.`);

    // First gather up the plain vanilla properties.
    const meterVisibility = { default: metered, override: Meter.VisibilityOverride.NONE };
    const barVisibility = { default: metered, override: Meter.VisibilityOverride.NONE };
    const newMeterProps = {
      columnId: id,
      groupId: group.id,
      name,
      color,
      meterVisibility,
      barVisibility,
      units,
    };

    // Now determine if there are any sensors or dependent calc columns et al.
    let sensor;
    if (typeof sensorId === 'number' && sensorId !== 0) {
      sensor = sensorWorld.getSensorById(sensorId);
    } else if (column.type === 'calc') {
      if (group && group.calcDependentGroups.length) {
        const dependentGroup = dataWorld.getColumnGroupById(group.calcDependentGroups[0]); // TODO: should we have reference to all dependentGroups?
        const dependentSensorId = dependentGroup ? dependentGroup.sensorId : null;

        if (typeof dependentSensorId === 'number' && sensorId === 0) {
          sensor = sensorWorld.getSensorById(dependentSensorId);
        }
      }
    }

    if (sensor) {
      newMeterProps.wavelength = sensor.wavelength;
      newMeterProps.sensorInfo = {
        autoId: sensor.autoId,
        name: sensor.name,
        id: sensor.id,
      };
    }

    const newMeter = new Meter(newMeterProps);

    this._addBindings(newMeter);

    // Finally register the meter with data world. This will assign it a UDM id
    // which we can hand off to the group.
    await dataWorld.addMeter(newMeter);

    group.meterId = newMeter.id;
    this._applyVisibilityOverrides();
  }

  /**
   * Runs all meters through the visibility override functions and re-assigns
   * visibilities as required.
   */
  _applyVisibilityOverrides() {
    this.dataWorld.meters.forEach(meter => {
      const column = this.dataWorld.getColumnById(meter.columnId);
      // Missing columns can happen during file-new churn. Safe to ignore as
      // these will eventually get pruned.
      if (!column) return;

      this._overrides.forEach(func => {
        const { bar: barVis, meter: meterVis } = func(column, meter);
        if (meterVis) meter.overrideMeterVisibility(meterVis);
        if (barVis) meter.overrideBarVisibility(barVis);
      });
    });
  }

  /**
   * Adds bindings to a meter, such as listeners for specific column and
   * column event property change events.
   * @param {Meter} meter
   * @private
   */
  _addBindings(meter) {
    if (meter.hasBindings) return;

    const { dataWorld, sensorWorld } = this;
    const {
      columnId,
      sensorInfo: { id: sensorId },
    } = meter;

    const column = dataWorld.getColumnById(columnId);
    const { group } = column;
    const sensor = sensorWorld.getSensorById(sensorId);

    // Add listeners for various column properties that are subject to change.
    meter.addBindings(binder => {
      binder.bind(
        group,
        'name-changed',
        action(name => {
          meter.name = name;
        }),
      );

      binder.bind(
        group,
        'units-changed',
        action(units => {
          meter.units = units;
        }),
      );

      binder.bind(
        column,
        'live-value-changed',
        action(value => {
          meter.value = column.getFormattedValue(value);
        }),
      );

      binder.bind(
        column,
        'color-changed',
        action(color => {
          meter.color = color;
        }),
      );

      if (sensor) {
        // This is for SA. The group.name is a composite of the column name
        // plus the wavelength.
        binder.bind(
          sensor,
          'wavelength-changed',
          action(wavelength => {
            if (wavelength) {
              meter.wavelength = wavelength;
              meter.name = group.name;
            }
          }),
        );
      }
    });
  }

  /**
   * Updates meter properties to match its associated column et al.
   * @param {Meter} meter
   * @public
   */
  updateMeterProperties(meter) {
    const { dataWorld } = this;
    const column = dataWorld.getColumnById(meter.columnId);
    const { id, name, color, group, sensorId } = column;

    meter.name = name;
    meter.units = group.units;
    meter.groupId = group.id;
    meter.columnId = id;
    if (color) meter.color = color;

    const { autoId, name: sensorName } = this.sensorWorld.getSensorById(sensorId) ?? {};
    if (autoId && name) {
      meter.sensorInfo = {
        id: sensorId,
        autoId,
        name: sensorName,
      };
    }

    column.meterId = meter.id;
  }
}
