import Analytics from '@services/analytics/analytics.js';
import { PopoverManager } from '@services/popovermanager/PopoverManager.js';
import { DataAnalysis } from '@services/analysis/DataAnalysis.js';
import { DataWorld } from '@services/dataworld/DataWorld.js';
import { SensorWorld } from '@services/sensorworld/SensorWorld.js';
import { DataCollection } from '@services/datacollection/DataCollection.js';
import { DeviceManager } from '@services/devicemanager/DeviceManager.js';
import { Udm } from '@services/Udm.js';

import { DataWorldAPI } from '@services/api/DataWorldAPI.js';
import { SensorWorldAPI } from '@services/api/SensorWorldAPI.js';
import { DeviceManagerAPI } from '@services/api/DeviceManagerAPI.js';
import { DataCollectionAPI } from '@services/api/DataCollectionAPI.js';
import { NativeModulesAPI } from '@services/api/NativeModulesAPI.js';
import { UdmAPI } from '@services/api/UdmAPI.js';

// TODO: deprecate these
import { enabledContent } from '@services/enabledcontent/enabledContent.js';
import { VSTAnalysisAPI } from '@services/api/VSTAnalysisAPI.js';

import { createSensorMap } from '@services/sensorworld/sensor-map.js';

// adapters
import { createAccessibility } from './adapters/accessibility';
import { createAppLifecycle } from './adapters/app-lifecycle';
import { createAppManifest } from './adapters/app-manifest';
import { createAppAutoUpdate } from './adapters/app-update';
import { createFileIO } from './adapters/file-io';
import { createToast } from './adapters/toast.js';
import { createRPCModule } from './adapters/rpcmodule';
import { createRPCMessageChannel } from './adapters/rpc-message-channel';
import { createWakelock } from './adapters/wake-lock';
import { createPermissons } from './adapters/permissions';
import { createStartupFileHandler } from './adapters/startup-file-handler';
import { ally } from './adapters/ally';
import { capture } from './adapters/window-capture';
import { platformReady } from './adapters/pre-services-startup';

const callbacks = [];

const progessUpdate = progress => {
  callbacks.forEach(cb => cb(progress));
};

export const addProgressCallback = callback => {
  callbacks.push(callback);
};

export const initServices = async () => {
  await platformReady();
  await new Promise(resolve => {
    if (window.wasmModuleReady || PLATFORM_ID !== 'web') {
      resolve();
    } else {
      document.addEventListener('wasm-ready', () => resolve());
    }
  });
  const resolves = await Promise.all([
    createAppLifecycle(),
    createAppManifest(),
    createSensorMap(),
    createRPCModule(),
  ]);
  const appLifecycle = resolves[0];
  const appManifest = resolves[1];
  const sensorMap = resolves[2];
  const rpcModule = resolves[3];

  const analytics = new Analytics();
  const messageChannel = await createRPCMessageChannel(rpcModule, progessUpdate);
  const power = createWakelock();
  const appUpdate = createAppAutoUpdate(appLifecycle);
  const toast = createToast();
  const accessibility = createAccessibility();
  const { rpc } = messageChannel;
  const enabledContentLib = enabledContent.create();
  const permissions = createPermissons();

  const popoverManager = new PopoverManager(toast, enabledContentLib);

  const dataCollection = new DataCollection({
    api: new DataCollectionAPI(rpc),
    nativeModulesAPI: new NativeModulesAPI(rpc),
    deviceServerPorts: messageChannel.ports || null,
    sensorMap,
    appManifest,
  });

  const sensorWorld = new SensorWorld({
    api: new SensorWorldAPI(rpc),
    sensorMap,
    dataCollection,
  });

  const udm = new Udm({
    api: new UdmAPI(rpc),
  });

  const dataWorld = new DataWorld({
    api: new DataWorldAPI(rpc),
    sensorWorld,
    dataCollection,
    appManifest,
    power,
    popoverManager,
    udm,
  });

  const deviceManager = new DeviceManager({
    api: new DeviceManagerAPI(rpc),
    dataWorld, // TODO: remove this dependency from DeviceManager
    permissions,
  });

  const dataAnalysis = new DataAnalysis({
    dataWorld,
    api: new VSTAnalysisAPI(rpc),
  });

  const fileIO = createFileIO(appManifest.getAppName(), dataWorld, popoverManager);

  const startupFileHandler = createStartupFileHandler(dataWorld, fileIO, toast);

  return {
    analytics,
    appLifecycle,
    appManifest,
    appUpdate,
    fileIO,
    sensorMap,
    toast,
    accessibility,
    deviceManager,
    sensorWorld,
    dataWorld,
    dataAnalysis,
    dataCollection,
    popoverManager,
    ally,
    capture,
    permissions,
    power,
    startupFileHandler,
    rpc,
    udm,
  };
};
